import React, { Fragment, useEffect, useState } from 'react';
import InputRadio from "../../../../components/ui/InputRadio";
import s from "./SimpleDiscount.module.scss";
import s_Block from "../../PromocodeSeriesEditView.module.scss";
import cn from "classnames";
import { VALIDATION } from "../../../../const/text";
import { INPUT_MAX_LENGTH } from "../../../../const/view";
import TreeCustom from "../TreeCustom";
import Button from "../../../../components/ui/Button";
import icon_delete from "../../../../assets/icons/icon_delete.svg";
import s_Select from "../../../OrderEditView/fakeData/orderStyle.module.scss";
import InputWrapper from "../../../../components/ui/InputWrapper";
import s_Input from "../../../../components/ui/InputWrapper/InputWrapper.module.scss";
import Group from "../../../../components/ui/FormEditView/Group";
import ColFull from "../../../../components/ui/FormEditView/ColFull";
import CheckboxSwitch from "../../../../components/ui/CheckboxSwitch";
import ColLeft from "../../../../components/ui/FormEditView/ColLeft";
import ColRight from "../../../../components/ui/FormEditView/ColRight";

const SimpleDiscount = ({ activeTab, data, formH, discountConditions, setDiscountConditions, activeCat }) => {
    const hasSimpleDiscountWatch = formH.watch('has_simple_discount');
    const actionTypeWatch = formH.watch('action_type');
    const isSpecialOfferCompatibleWatch = formH.watch('is_special_offer_compatible');

    const handleOnChange = (e, index, name, setArray) => {
        setArray(prev => {
            const result = [...prev];
            result[index][name] = e.target.value;
            return [...result];
        });
    };

    const handleAddDiscountCondition = () => {
        setDiscountConditions((prev) => {
            if(prev.length !== 0) {
                return [...prev, {attribute: "product|category_ids", action_type: "", value: "", discount_amount: ""}];
            } else {
                return [{attribute: "product|category_ids", action_type: "", value: "", discount_amount: ""}];
            }
        });
    };

    const removeHandler = (el, setArray) => {
        setArray(prev => {
            const temp = [...prev];
            const index = temp.indexOf(el);
            temp.splice(index, 1);
            return temp;
        })
    };

    return (
        <div style={{
            display: activeTab === 1 ? 'block' : 'none',
            height: activeTab === 1 ? 'auto' : 0,
        }}>
            {/* is_special_offer_compatible */}
            <Group attrs={{ style: { margin: '0 0 20px 0', paddingBottom: '20px', borderBottom: '1px solid #d8dfec' } }}>
                <ColFull attrs={{ style: { maxWidth: '320px' } }}>
                    <CheckboxSwitch
                        name="has_simple_discount"
                        id="ps-has-simple-discount"
                        label="Складне налаштування:"
                        formH={formH}
                        defaultChecked={data?.fields?.has_simple_discount}
                    />
                </ColFull>
            </Group>

            {hasSimpleDiscountWatch ? (
                <div style={{ marginBottom: '1.5rem' }}>
                    {discountConditions.length !== 0 && discountConditions.map((item, index) => {
                        return (
                            <div className={s_Block.conditions__block} key={`discount-condition-item-id-${index}`}>
                                {/* discount_conditions[].attribute */}
                                <div style={{ width: "13%"  }}>
                                    <div className={s_Select.select__inner}>
                                        <div className={s_Select.select__wrapper}>
                                            <select
                                                className={cn(s_Select.select, {
                                                    'is-error': formH.errors?.discount_conditions?.[index]?.attribute,
                                                })}
                                                id={`ps-discount-conditions-attribute-${index}`}
                                                value={item?.attribute}
                                                onChange={e => handleOnChange(e, index, 'attribute', setDiscountConditions)}
                                            >
                                                {data?.conditions && data?.conditions['condition_attributes']?.map((condit) => {
                                                    if (condit?.key === 'product') {
                                                        return condit?.children?.map((it, number) => {
                                                            if ((it?.key === 'product|category_ids') || (it?.key === 'product|jde') || (it?.key === 'product|selection') ) {
                                                                return <option value={it.key} key={`option-discount-condition-attribute-${number}`} selected={item.attribute === it.key}>{it.label}</option>;
                                                            }

                                                            return null;
                                                        });
                                                    }

                                                    return null;
                                                })}
                                            </select>
                                            <label className={s_Select.label} htmlFor={`ps-discount-conditions-attribute-${index}`}>Об'єкт</label>
                                        </div>
                                        {formH.errors?.discount_conditions?.[index]?.attribute && <p className="form-input-hint">
                                            {formH.errors?.discount_conditions[index]?.attribute?.message}
                                        </p>}
                                        <input
                                            name={`discount_conditions[${index}].attribute`}
                                            value={discountConditions[index].attribute}
                                            type="hidden"
                                            ref={formH.register({
                                                required: VALIDATION.select.required,
                                            })}
                                        />
                                    </div>
                                </div>

                                {item?.attribute === 'product|category_ids' ? (
                                    <div style={{ width: "32%" }}>
                                        <div className={s_Select.select__inner}>
                                            <div className={s_Select.select__wrapper}>
                                                <select
                                                    name={`discount_conditions[${index}].value`}
                                                    style={{ marginLeft: "10px" }}
                                                    className={cn(s_Select.select, {
                                                        'is-error': formH.errors.action_type,
                                                    })}
                                                    id={`ps-discount-conditions-${index}-value`}
                                                    value={item?.value}
                                                    onChange={e => handleOnChange(e, index, 'value', setDiscountConditions)}
                                                    ref={formH.register({
                                                        required: VALIDATION.select.required,
                                                    })}
                                                >
                                                    {data && data?.categories_action?.map((category) => {
                                                        const concatCat = () => {
                                                            let result = [];
                                                            if (activeCat) {
                                                                activeCat.forEach((itemCat) => {
                                                                    itemCat?.value.forEach((itemValue) => {
                                                                        result.push(+itemValue)
                                                                    })
                                                                })
                                                            }

                                                            if (!!result?.length) {
                                                                result = [...new Set(result)];
                                                            }

                                                            return result;
                                                        }

                                                        const result = concatCat()

                                                        if (result && result?.includes(category?.value)) {
                                                            return (
                                                                <option
                                                                    key={`discount-conditions-value-${category.value}`}
                                                                    value={category.value}
                                                                    selected={item?.value === category.value}
                                                                >
                                                                    {category.label}
                                                                </option>
                                                            )
                                                        }

                                                        return null;
                                                    })}
                                                </select>
                                                <label className={s_Select.label} htmlFor={`ps-discount-conditions-${index}-value`}>Обрати категорію</label>
                                            </div>
                                        </div>
                                    </div>
                                ) : item?.attribute === 'product|selection' ? (
                                    <div style={{ width: "32%" }}>
                                        <div className={s_Select.select__inner}>
                                            <div className={s_Select.select__wrapper}>
                                                <select
                                                    name={`discount_conditions[${index}].value`}
                                                    style={{ marginLeft: "10px" }}
                                                    className={cn(s_Select.select, {
                                                        'is-error': formH.errors.action_type,
                                                    })}
                                                    id={`ps-discount-conditions-${index}-value`}
                                                    value={item?.value}
                                                    onChange={e => handleOnChange(e, index, 'value', setDiscountConditions)}
                                                    ref={formH.register({
                                                        required: VALIDATION.select.required,
                                                    })}
                                                >
                                                    {data && data?.selections?.map((category) => {
                                                        return (
                                                            <option
                                                                key={`discount-conditions-value-${category.id}`}
                                                                value={category.id}
                                                                selected={item?.value === category.id}
                                                            >
                                                                {category.title}
                                                            </option>
                                                        )
                                                    })}
                                                </select>
                                                <label className={s_Select.label} htmlFor={`ps-discount-conditions-${index}-value`}>Обрати вибірку</label>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div style={{ width: "32%" }}>
                                        <InputWrapper
                                            label={"Введіть значення JDE"}
                                            id={`ps-discount-conditions-${index}-value`}
                                            errorComponent={formH.errors?.discount_conditions?.[index]?.value && <p className="form-input-hint">
                                                {formH.errors?.discount_conditions?.[index]?.value?.message}
                                            </p>}
                                        >
                                        <input
                                            name={`discount_conditions[${index}].value`}
                                            className={cn(s_Input.input, {
                                                'is-error': formH.errors?.discount_conditions?.[index]?.value,
                                            })}
                                            type="text"
                                            id={`ps-discount-conditions-${index}-value`}
                                            placeholder={ "Введіть значення JDE"}
                                            value={item.value}
                                            onChange={e => handleOnChange(e, index, 'value', setDiscountConditions)}
                                            maxLength={INPUT_MAX_LENGTH}
                                            ref={formH.register({
                                                required: VALIDATION.req.required,
                                            })}
                                        />
                                        </InputWrapper>
                                    </div>
                                )}

                                {/* discount_conditions[].action_type */}
                                <div style={{ width: "30%" }}>
                                    <div className={s_Select.select__inner}>
                                        <div className={s_Select.select__wrapper}>
                                            <select
                                                name={`discount_conditions[${index}].action_type`}
                                                style={{ marginLeft: "10px" }}
                                                className={cn(s_Select.select, {
                                                    'is-error': formH.errors?.discount_conditions?.[index]?.action_type,
                                                })}
                                                id={`ps-discount-conditions-action-type-${index}`}
                                                value={item.action_type}
                                                onChange={e => handleOnChange(e, index, 'action_type', setDiscountConditions)}
                                                ref={formH.register({
                                                    required: VALIDATION.select.required,
                                                })}
                                            >
                                                {data && data?.action_types?.map((type) => {
                                                    if (type?.key === "by_special_offer") {
                                                        return null;
                                                    }
                                                    return (
                                                        <option
                                                            key={`discount_conditions_${type.sort}`}
                                                            value={type.key}
                                                            selected={item?.action_type === type.key}
                                                        >
                                                            {type.name}
                                                        </option>
                                                    )
                                                })}
                                            </select>
                                            <label className={s_Select.label} htmlFor={`ps-discount-conditions-action-type-${index}`}>Тип знижки</label>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ width: "15%" }}>
                                    <InputWrapper
                                        label="Розмір знижки, %"
                                        id={`ps-conditions-value-text-${index}`}
                                        errorComponent={formH.errors?.discount_conditions?.[index]?.discount_amount && <p className="form-input-hint">
                                            {formH.errors?.discount_conditions?.[index]?.discount_amount?.message}
                                        </p>}
                                    >
                                        <input
                                            name={`discount_conditions[${index}].discount_amount`}
                                            className={cn(s_Input.input, {
                                                'is-error': formH.errors?.discount_conditions?.[index]?.discount_amount,
                                            })}
                                            type="text"
                                            id={`ps-discount-conditions-${index}-discount-amount`}
                                            placeholder="Розмір знижки, %"
                                            value={item?.discount_amount}
                                            onChange={e => handleOnChange(e, index, 'discount_amount', setDiscountConditions)}
                                            maxLength={INPUT_MAX_LENGTH}
                                            ref={formH.register({
                                                required: VALIDATION.req.required,
                                            })}
                                        />
                                    </InputWrapper>
                                </div>
                                <div>
                                    <button
                                        type="button"
                                        className={s.btn_danger}
                                        onClick={() => removeHandler(item, setDiscountConditions)}
                                    >
                                        <img src={icon_delete} alt="icon delete" />
                                    </button>
                                </div>
                            </div>
                        )
                    })}

                    <div style={{ marginTop: '10px' }}>
                        <Button purple onClick={handleAddDiscountCondition}>Добавити</Button>
                    </div>
                </div>
            ) : (
                <>
                    {/* action_type */}
                    <div className={s.action_type}>
                        <div>
                            <div className={s_Select.select__inner}>
                                <div className={s_Select.select__wrapper}>
                                    <select
                                        name="action_type"
                                        className={cn(s_Select.select, {
                                            'is-error': formH.errors.action_type,
                                        })}
                                        id="ps-action-type"
                                        ref={formH.register({
                                            required: VALIDATION.select.required,
                                        })}
                                    >
                                        <option value="">Оберіть тип</option>
                                        {data && data?.action_types?.map((type) => {
                                            return (
                                                <option
                                                    key={type.sort}
                                                    value={type.key}
                                                    selected={data?.fields?.action_type === type.key}
                                                >
                                                    {type.name}
                                                </option>
                                            )
                                        })}
                                    </select>
                                    <label className={s_Select.label} htmlFor="ps-action-type">Тип знижки</label>
                                </div>
                                {formH.errors.action_type && <p className="form-input-hint" style={{ color: '#e85600' }}>{formH.errors.action_type.message}</p>}
                            </div>
                        </div>
                        {actionTypeWatch !== 'by_special_offer' && (
                            <div>
                                <InputWrapper
                                    label="Розмір знижки"
                                    id="ps-discount-amount"
                                    errorComponent={formH.errors?.discount_amount &&
                                    <p className="form-input-hint">{formH.errors?.discount_amount?.message}</p>}
                                >
                                    <input
                                        name="discount_amount"
                                        className={s_Input.input}
                                        type="number"
                                        id="ps-discount-amount"
                                        placeholder="Розмір знижки"
                                        maxLength={10}
                                        ref={formH.register}
                                    />
                                </InputWrapper>
                            </div>
                        )}
                    </div>
                </>
            )}

            {actionTypeWatch !== 'by_special_offer' && (
                <>
                    <div>
                        {/* discount_cart_quantity */}
                        <InputWrapper
                            label="Загальне обмеження для кошика"
                            id="ps-restrictions-same-product-quantity"
                            errorComponent={formH.errors?.discount_cart_quantity &&
                                <p className="form-input-hint">{formH.errors?.discount_cart_quantity?.message}</p>}
                        >
                            <input
                                name="discount_cart_quantity"
                                className={s_Input.input}
                                type="number"
                                id="ps-restrictions-same-product-quantity"
                                placeholder="Загальне обмеження для кошика"
                                maxLength={10}
                                ref={formH.register}
                            />
                        </InputWrapper>
                    </div>
                    <div>
                        {/* discount_quantity */}
                        <InputWrapper
                            label="Обмеження щодо однакового товару"
                            id="ps-restrictions-general-product-quantity"
                            errorComponent={formH.errors?.discount_quantity &&
                                <p className="form-input-hint">{formH.errors?.discount_quantity?.message}</p>}
                        >
                            <input
                                name="discount_quantity"
                                className={s_Input.input}
                                type="number"
                                id="ps-restrictions-general-product-quantity"
                                placeholder="Обмеження щодо однакового товару"
                                maxLength={10}
                                ref={formH.register}
                            />
                        </InputWrapper>
                    </div>
                    <div>
                        {/* discount_per_coupon_limit */}
                        <InputWrapper
                            label="Макс. сума знижки за промокодом"
                            id="ps-discount-per-coupon-limit"
                            errorComponent={formH.errors?.discount_per_coupon_limit &&
                                <p className="form-input-hint">{formH.errors?.discount_per_coupon_limit?.message}</p>}
                        >
                            <input
                                name="discount_per_coupon_limit"
                                className={s_Input.input}
                                type="number"
                                id="ps-discount-per-coupon-limit"
                                placeholder="Макс. сума знижки за промокодом для одного кошика"
                                maxLength={INPUT_MAX_LENGTH}
                                ref={formH.register}
                            />
                        </InputWrapper>
                    </div>
                    <div>
                        {/* discount_cart_limit */}
                        <InputWrapper
                            label="Макс. сума знижки за промокодом для одного кошика"
                            id="ps-discount-cart-limit"
                            errorComponent={formH.errors?.discount_cart_limit &&
                                <p className="form-input-hint">{formH.errors?.discount_cart_limit?.message}</p>}
                        >
                            <input
                                name="discount_cart_limit"
                                className={s_Input.input}
                                type="number"
                                id="ps-discount-cart-limit"
                                placeholder="Макс. сумма скидки по промокоду для одной корзины"
                                maxLength={INPUT_MAX_LENGTH}
                                ref={formH.register}
                            />
                        </InputWrapper>
                    </div>
                </>
            )}

            {/* end_other_rules */}
            <Group attrs={{style: {margin: 0}}}>
                <ColFull attrs={{style: {maxWidth: '400px'}}}>
                    <CheckboxSwitch
                        name="end_other_rules"
                        id="ps-end-other-rules"
                        label="Скасувати решту правил для кошика"
                        fontWeight={400}
                        formH={formH}
                        defaultChecked={data?.fields?.end_other_rules}
                    />
                </ColFull>
            </Group>
        </div>
    );
};

export default SimpleDiscount;