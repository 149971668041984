import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from 'react-router-dom';
import {Helmet} from "react-helmet";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import getSplicedData from "../../lib/crud/getSplicedData";
import useIsMounted from "../../lib/useIsMounted";
import useList from "../../lib/crud/useList";
import TableListViewTable from "../../components/common/TableListViewNew/Table";
import TableListViewPagination from "../../components/common/TableListViewNew/Pagination";
import TableListViewRow from "../../components/ui/DragComponents/Row";
import TableListViewPerPage from "../../components/common/TableListViewNew/SelectPerPage";
import TableListViewRowLoader from "../../components/common/TableListViewNew/Row/RowLoader";
import {edit, getFiltersData, remove} from "./api";
import TopNavTabs from "../../components/ui/TopNavTabsNew/TopNavTabs";
import checkPermission from "../../lib/checkPermission";
import TopPagination from "../../components/common/TableListViewNew/TopPagination";
import TableHead from "../../components/common/TableListViewNew/TableHead";
import TopPanelNew from "../../components/common/TopPanelNew";
import errorMessage from "../../lib/errorMessage";
import {refreshSortBankSelections, updateSortBankSelections} from "../../lib/api/sort";
import Button from "../../components/ui/Button";
import icon_save from "../../assets/icons/icon_save.svg";
import {toast} from "react-toastify";
import Portal from "../../components/ui/Portal";
import ModalCreate from "./Modal/ModalCreate";
import IconAdd from "../../components/icon/iconAdd";
import getTZtoDate from "../../lib/getTZtoDate";
import StatusCircle from "../../components/ui/StatusCircle";
import TableListViewFiltersRow from "../../components/common/TableListViewNew/FiltersRow";
import {getBanksSelections} from "../../lib/api/banks";
import {SortableContext, verticalListSortingStrategy} from "@dnd-kit/sortable";
import styles from "./SortBank.module.scss";
import {closestCenter, DndContext, KeyboardSensor, MouseSensor, TouchSensor, useSensor, useSensors} from "@dnd-kit/core";
import {restrictToVerticalAxis} from "@dnd-kit/modifiers";
import sSelect from "../OrderEditView/fakeData/orderStyle.module.scss";
import getImgUrl from "../../lib/helpers/getImgUrl";

const SortBankPPList = () => {
    const [selectedSort, setSelectedSort] = useState([]);
    const [sortArray, setSortArray] = useState([1]);
    const [currentCard, setCurrentCard] = useState(null);
    const [openEdit, setOpenEdit] = useState(false);

    const [activeModal, setActiveModal] = useState(false);
    const [activeId, setActiveId] = useState(false);
    const [activeRowId, setActiveRowId] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();
    const isMounted = useIsMounted();
    const {
        rest,
        filtersData,
        load,
        setLoad,
        urlParams,
        setRest,
        pushUrlParams,
    } = useList({
        api: getBanksSelections,
        apiFilters: getFiltersData,
        location,
        navigate,
        isMounted,
    });

    const handleOnClose = () => {
        const portal = document.getElementById("modal-root");
        portal.className = "";
        setOpenEdit(false);
        setActiveModal(false);
    }

    const handleOnOpen = () => setOpenEdit(true);

    const handleSelectSort = (e, value) => {
        setSelectedSort(prev => {
            const temp = [...prev];

            const parseItem = {
                id: e?.id,
                position: +value
            };

            if (!!temp?.length){
                const findSortIndex = temp.findIndex(itemSelect => itemSelect.id === parseItem.id)

                if (findSortIndex !== -1){
                    temp[findSortIndex] = parseItem;
                    return temp;
                }
                temp.push(parseItem);
            } else {
                temp.push(parseItem);
            }

            return temp;
        })
    }

    const handleUpdateSort = async() => {
        setLoad(true);
        await updateSortBankSelections({
            page: rest?.current_page,
            per_page: rest?.meta?.total,
            filter: +getFilterFromLocation(location),
            positions: selectedSort
        })
            .then((res) => {
                setSelectedSort([]);
                setRest(prev => {
                    const temp = {...prev};

                    temp.data = res.data?.data;

                    return temp;
                })
            })
            .catch(err => errorMessage(err, ''))
            .finally(() => setLoad(false));
    }

    const handleRefreshSort = async() => {
        setLoad(true);
        await refreshSortBankSelections({
            filter: +getFilterFromLocation(location),
            page: rest?.current_page,
            per_page: rest?.meta?.total,
        })
            .then((res) => {
                setRest(prev => {
                    const temp = {...prev};
                    temp.data = res.data?.data;
                    return temp;
                });
                toast('Рефреш виконано', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
            .catch(err => errorMessage(err, ''))
            .finally(() => setLoad(false));
    }

    useEffect(() => {
        if (rest?.meta?.total){
            const totalArray = [...Array(rest?.meta?.total + 1).keys()].filter(item => !!item);
            if (!!rest?.data?.length){
                rest?.data?.forEach(item => {
                    if (!totalArray?.includes(item?.position)){
                        totalArray.push(item?.position)
                    }
                })
            }
            setSortArray(totalArray);
        }
    }, [rest?.meta?.total, rest]);


    const getBanksPosition = (ids) => {
        return ids.map(id => {
            const match = filtersData?.banks?.data.find(item => item.id === id);

            return match ? <img
                draggable="false"
                src={getImgUrl(match?.img)}
                alt={match?.title_ua || 'Банк'}
                className={styles.bank__image}
            /> : null;
        }).filter(img => img !== null);
    }

    const sensors = useSensors(
        useSensor(MouseSensor, {}),
        useSensor(TouchSensor, {}),
        useSensor(KeyboardSensor, {})
    );

    const handleDragStart = (event) => {
        setActiveRowId(event.active.id);
    }

    const handleDragEnd = async(event) => {
        const {active, over} = event;

        if (active.id !== over.id){
            const id = active.id
            const index = over?.data?.current?.sortable?.index + 1

            await edit({
                page: rest?.current_page,
                per_page: rest?.meta?.total,
                filter: +getFilterFromLocation(location),
            })(id, index).then((res) => setRest(prev => {
                const temp = {...prev};
                temp.data = res.data?.data;

                return temp;
            }))

            setActiveRowId(null);
        }
    }

    const handleDragCancel = () => {
        setActiveRowId(null);
    }

    function getFilterFromLocation(location) {
        const params = new URLSearchParams(location.search);
        return params.get('filter');
    }

    return (
        <>
            <TopPanelNew
                title='Сортування'
                breadcrumbs={{
                    items: [
                        {
                            title: 'Головна',
                            url: '/app',
                            permissionName: 'admin.sort.category-sort',
                        },
                        {
                            title: 'Сортування',
                            url: `/app/sort/categories${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
                            permissionName: 'admin.sort.category-sort',
                        },
                    ],
                }}
                customButtonTop={checkPermission('admin.sort.category-sort.update')
                    ? (
                        <>
                            <Button onClick={handleUpdateSort} disabled={!selectedSort?.length} purple>
                                <img src={icon_save} alt="icon save"/>
                                Зберегти / Сортувати
                            </Button>
                        </>
                    ) : null
                }
                positionSticky
            />

            <div>
                <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Bank pay-part sort`}/>
                {!load && (
                    <TopPagination
                        tabs={(
                            <TopNavTabs tabs={[
                                {
                                    title: 'Товари',
                                    url: '/app/sort/products',
                                    permissionName: 'admin.sort.products'
                                },
                                {
                                    title: 'Банера',
                                    url: '/app/sort/banner/catalog',
                                    permissionName: 'admin.sort.banner-catalog'
                                },
                                {
                                    title: 'Акції',
                                    url: '/app/sort/special-offers',
                                    permissionName: 'admin.sort.special-offers'
                                },
                                {
                                    title: 'Категорії супутніх товарів',
                                    url: '/app/sort/categories',
                                    permissionName: 'admin.sort.category-sort'
                                },
                                {
                                    title: 'Банки ОЧ',
                                    url: '/app/sort/banks-pay-part',
                                    permissionName: 'admin.sort.bank-selection',
                                    isActive: true
                                }
                            ]}
                            />
                        )}
                        pagination={(
                            <>
                                {rest?.meta?.total ? (
                                    <>
                                        <TableListViewPagination
                                            length={+rest?.meta?.last_page}
                                            current={+rest?.meta?.current_page}
                                            loc={location}
                                            navigate={navigate}
                                        />
                                        <TableListViewPerPage
                                            urlParams={urlParams}
                                            onSelect={(val, params) => pushUrlParams(params)}
                                            total={+rest?.meta?.total}
                                        />
                                    </>
                                ) : null}
                            </>
                        )}
                        stickySecond
                    />
                )}
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 28}}>
                    <div style={{
                        display: checkPermission('admin.sort.banks-pay-part.refresh')
                            ? 'flex'
                            : 'none',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        gap: '16px', minWidth: "30%"
                    }}>
                        <Button
                            onClick={handleOnOpen}
                            style={{
                                width: "100%",
                                display: checkPermission('admin.banks-pay-part.move-to')
                                    ? 'flex'
                                    : 'none'
                            }}
                            border
                        >
                            <IconAdd color="#5755d1"/>
                            Додати вибірку
                        </Button>

                        <div className={sSelect.select__wrapper} style={{width: "150%"}}>
                            <select
                                name="filter-banks-pay-part"
                                className={sSelect.select}
                                id="filter-banks-pay-part"
                                defaultValue={urlParams?.category_id}
                                style={{padding: "4px 16px", height: "44px"}}
                                onChange={(e) => pushUrlParams({
                                    ...urlParams,
                                    filter: e.target.value
                                })}>
                                <option value={0}>Показати все</option>
                                <option value={1} selected>Показати актуальні</option>
                                <option value={2}>Показати не активні</option>
                            </select>
                        </div>
                    </div>

                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '16px'}}>
                        <Button
                            onClick={handleRefreshSort}
                            style={{
                                display: checkPermission('admin.bank-selection.refresh')
                                    ? 'flex'
                                    : 'none'
                            }}
                            purple
                        >
                            Рефреш сортування
                        </Button>
                    </div>
                </div>
                <DndContext
                    sensors={sensors}
                    onDragEnd={handleDragEnd}
                    onDragStart={handleDragStart}
                    onDragCancel={handleDragCancel}
                    collisionDetection={closestCenter}
                    modifiers={[restrictToVerticalAxis]}
                >
                    <TableListViewTable>
                        <TableHead
                            arrayChildren={[{
                                title: 'ID',
                                attr: {style: {width: '50px'}}
                            }, 'Назва', 'Позиція', {
                                title: 'Діє від',
                                attr: {style: {width: '150px'}}
                            }, {
                                title: 'Діє до',
                                attr: {style: {width: '150px'}}
                            }, {
                                title: 'Дата створення / редагування',
                                attr: {style: {width: '150px'}}
                            }, {
                                title: 'Порядок банків ОЧ',
                                attr: {style: {width: '340px', maxWidth: "340px"}}
                            }, {
                                title: 'Активність',
                                attr: {style: {width: '100px'}}
                            }
                            ]}
                            filterChildren={<TableListViewFiltersRow
                                urlParams={urlParams}
                                fields={[
                                    {},
                                    {
                                        type: 'text', name: 'name', placeholder: 'Пошук', attr: {
                                            style: {
                                                width: '100%',
                                                maxWidth: '450px',
                                            }
                                        }
                                    },
                                    {},
                                    {type: 'date', name: 'starts_at'},
                                    {type: 'date', name: 'finish_at'},
                                    {type: 'date', name: 'created_at'},
                                    {},
                                    {
                                        type: 'select', name: 'is_active', options: [
                                            {
                                                id: 0,
                                                title: 'Вимкнено',
                                            },
                                            {
                                                id: 1,
                                                title: 'Активна'
                                            }
                                        ]
                                    },
                                    {},

                                ]}
                                onSubmit={pushUrlParams}
                            />}
                        />
                        <tbody>
                        {load
                            ? (
                                <TableListViewRowLoader cols={4}/>
                            )
                            : (rest && (
                                rest?.data?.length
                                    ? <SortableContext items={rest?.data} strategy={verticalListSortingStrategy}>
                                        {rest?.data?.map((e, i) => (
                                            <TableListViewRow
                                                id={e.id}
                                                key={`bank-pp-item-${e.id}`}
                                                remove={{
                                                    api: () => remove(e.id),
                                                    success: () => setRest(getSplicedData(i, rest)),
                                                    alert: 'Вибірку видалено',
                                                }}
                                                permissionRemove='admin.bank-selection.destroy'
                                                setActiveModal={setActiveModal}
                                                setActiveId={setActiveId}
                                                tagA={false}
                                                editLink
                                                card={e}
                                                ModalTrigger={true}

                                                setCurrentCard={setCurrentCard}
                                                title={'Видалити налаштування "' + e?.name + '"?'}

                                                custom={true}
                                            >
                                                <td>
                                                    <span>{e?.name || '---'}</span>
                                                </td>
                                                <td>
                                                    <select
                                                        name={`sort-name-${e.id}`}
                                                        className="form-select"
                                                        onChange={(event) => handleSelectSort(e, event.target.value)}
                                                        defaultValue={e?.position}
                                                    >
                                                        {sortArray.map((item) => (
                                                            <option value={item} key={`sort-name-${item}`}>
                                                                {item}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </td>
                                                <td>
                                                    <span>{e?.starts_at ? getTZtoDate(e?.starts_at) : '--'}</span>
                                                </td>
                                                <td>
                                                    <span>{e?.finish_at ? getTZtoDate(e?.finish_at) : '--'}</span>
                                                </td>
                                                <td>
                                                    <span>{e?.created_at ? getTZtoDate(e?.created_at) : '--'}</span>
                                                </td>
                                                <td>
                                                    <div style={{display: "flex", gap: "10px", flexWrap: "wrap"}}>
                                                        {e?.bank_ids?.length ? getBanksPosition(e?.bank_ids) : "---"}
                                                    </div>
                                                </td>
                                                <td>
                                            <span>
                                              {e.is_active ? <StatusCircle green text="Активно"/> : <StatusCircle red text="Вимкнено"/>}
                                            </span>
                                                </td>
                                            </TableListViewRow>
                                        ))}
                                    </SortableContext>
                                    : (
                                        <TableListViewRow disabled>
                                            <td colSpan={4} style={{paddingTop: '22px'}}>Немає вибірок</td>
                                        </TableListViewRow>
                                    )
                            ))}
                        </tbody>
                    </TableListViewTable>
                </DndContext>
            </div>

            {openEdit && (
                <Portal children={<ModalCreate
                    onClose={handleOnClose}
                    rest={rest}
                    filtersData={filtersData}
                    title={'Додати вибірку для сортування банків ОЧ'}
                />}/>
            )}

            {activeModal && (
                <Portal children={<ModalCreate
                    rest={rest}
                    onClose={handleOnClose}
                    filtersData={filtersData}
                    activeId={activeId}
                    title={'Редагувати сортування банків ОЧ'}
                />}/>
            )}
        </>
    );
};

export default SortBankPPList;
