import React from 'react';
import { uuid } from 'uuidv4';
import s from "./NavFormTabs.module.scss";
import cn from "classnames";

const NavFormTabs = ({ tabs, setActiveTab, style }) => (
    <ul className={s.tab} style={style}>
        {!!tabs.length && tabs.map((el) => {
            return (
                <li className={cn(s.tab__item, {
                    ['active']: el?.isActive,
                    [s.tab__line]: el?.isActive,
                })} key={`${uuid()}`} onClick={() => setActiveTab(el.id)}>
                    {el.title}
                </li>
            )
        })}
    </ul>
)

export default NavFormTabs;
