import React, {useEffect, useState} from "react";
import { uid } from "react-uid";
import { Link, useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "./Breadcrumbs";
import s from "./topPanel.module.scss";
import icon_copy from "../../../assets/icons/icon_copy.svg";
import icon_arrow_down from "../../../assets/icons/icon_arrow-down.svg";
import icon_arrow_back from "../../../assets/icons/icon_arrow-back.svg";
import Button from "../../../components/ui/Button";
import icon_accept from "../../../assets/icons/icon_accept.svg";
import icon_save from "../../../assets/icons/icon_save.svg";
import icon_shipment from "../../../assets/icons/icon_shipment.svg";
import icon_document from "../../../assets/icons/icon_document.svg";
import icon_email from "../../../assets/icons/icon_email.svg";
import icon_ttn from "../../../assets/icons/icon_ttn.svg";
import icon_file_log from "../../../assets/icons/icon_file-log.svg";
import icon_return_product from "../../../assets/icons/icon_return_product.svg";
import icon_check from "../../../assets/icons/icon_check.svg";
import icon_arrow_right from "../../../assets/icons/icon_arrow-right.svg";
import icon_lock from '../../../assets/icons/icon_lock.svg';
import getTZtoDate from "../../../lib/getTZtoDate";
import { useDispatch, useSelector } from "react-redux";
import Constants from "../../../store/orderEdit/constants";
import {editOrder, removeOrderCouponPks} from "../../../lib/api/order";
import { toast } from "react-toastify";
import Loader from "../../../components/ui/Loader";
import { scrollIntoViewHelper } from "../../../lib/helpers/scrollIntoViewHelper";
import { parseDataGet }from '../api';
import { parseAll } from "../../../lib/api/_parseApi";
import errorMessage from "../../../lib/errorMessage";
import filterStatusOrder from "../../../lib/helpers/filterStatusOrderShopManager";
import cn from "classnames";
import {refreshToken, refreshTokenAndCb} from "../../../lib/crud/refreshToken";
import confirmDialog from "../../../lib/confirmDialog";
import {WONT_REMOVE} from "../../../const/text";

const TopPanel = ({
                      formH,
                      formProps,
                      data,
                      setData,
                      breadcrumbs,
                      editBtn,
                      openButton,
                      setOpenButton,
                      setActiveShipment,
                      setActiveLogOnOrder,
                      setActiveFiscalReceipt,
                      setActivePrivateLog,
                      setActiveInvoice,
                      setActiveReturnProduct,
                      setActiveSendSMS,
                      setActiveTtn,
                      copyShow,
                      setCopyShow,
                      editManager,
                      checkEdit,
                      productsData,
                      setProductsData,
                      setRequiredFields,
                      totalPriceData,
                      setTotalPriceData,
                      orderStatus,
                      setOrderStatus,
                      setOldOrderStatus
}) => {
    const dispatch = useDispatch();
    const orderEdit = useSelector(state => state.orderEdit);
    const [load, setLoad] = useState(false);
    const [statusChanges, setStatusChanges] = useState([]);

    const { id: orderID } = useParams();

    const onChange = (key, value) => dispatch({type: Constants.ON_CHANGE, payload: {[key]: value}});
    const clearOrderEditData = () => dispatch({type: Constants.CLEAR_FORM, payload: {}});

    const navigate = useNavigate();

    const [orderStatusShow, setOrderStatusShow] = useState(false);
    const [orderStatusCheck, setOrderStatusCheck] = useState('');
    const dataFields = formProps.data && formProps.data.fields;
    const form = formProps.formH;

    const handleOpenButton = () => {
        setOpenButton(!openButton);
    };

    const handleCopyText = () => {
        let textField = document.createElement('textarea');
        textField.innerText = `Samsung: Заказ #${formProps?.data?.fields?.id} от ${getTZtoDate(formProps?.data?.fields?.created_at, true)}`;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        setCopyShow(true);
        textField.remove();
        setTimeout(() => {
            setCopyShow(false);
        }, 2000);
    };

    const handleOrderStatus = (id) => {
        setOrderStatus(id);
        setOldOrderStatus(orderStatus);
        setOrderStatusShow(false);
    };

    useEffect(() => {
        onChange('status', orderStatus)
    }, [orderStatus])

    const saveHandler = async () => {
        // ps.publish(EDIT.save);
        if (formProps?.data?.fields?.discount_bpm !== 0
            && (orderEdit?.status_payment?.toString() === '1' || formProps?.data?.fields?.status_payment?.toString() === '1')
            && (orderEdit?.delivery_type?.toString() === '6' || formProps?.data?.fields?.delivery_type?.toString() === '6')
            && orderEdit?.status?.toString() === '4'
        ) {
            toast.error("Скасувати списання бонусів щоб продовжити", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            return
        }

        if(orderEdit?.order_comment?.text === '') {
            delete orderEdit.order_comment
        }

        if(!Object?.values(formProps?.formH?.errors)?.length) {
            setLoad(true);

            if(orderEdit?.order_comment?.text === null) {
                orderEdit['order_comment'] = null;
            }

            if(orderEdit?.delivery) {
                if(orderEdit?.delivery?.address?.street === null) {
                    orderEdit['delivery']['address'] = null;
                }
            }

            const parseDataSend = parseAll(orderEdit, true);

            if (!!parseDataSend?.products?.length) {
                const findDiscount = parseDataSend?.products?.find(item => {
                    if (item?.discount_amount && (item?.discount_amount > 0)) {
                        return item;
                    }
                    if (item?.discount_percent && (item?.discount_percent > 0)) {
                        return item;
                    }
                });

                if (findDiscount) {
                    await removeOrderCouponPks(formProps?.orderID)
                        .then((res) => {
                            const parseData = parseDataGet(res?.data, formProps?.orderID);

                            if (formProps?.setData) {
                                formProps?.setData(prev => {
                                    const temp = {...prev};

                                    temp.fields = parseData;

                                    return temp;
                                })
                            }

                        })
                        .catch(async (err) => await errorMessage(err, ''))
                }

                const findChangeQuantityProduct = parseDataSend?.products?.filter(item => item?.quantity);
                const findChangeAdditionalServicesProduct = parseDataSend?.products?.filter(item => !!item?.additional_services?.length);

                if (!!findChangeQuantityProduct?.length || !!findChangeAdditionalServicesProduct?.length) {

                    if (formProps?.data?.fields?.has_pks_discount) {
                        await removeOrderCouponPks(formProps?.orderID)
                            .then((res) => {
                                const parseData = parseDataGet(res?.data, formProps?.orderID);

                                if (formProps?.setData) {
                                    formProps?.setData(prev => {
                                        const temp = {...prev};

                                        temp.fields = parseData;

                                        return temp;
                                    })
                                }
                                if (!!res?.data?.products?.length) {
                                    res?.data?.products?.map((c, id) => {
                                        setProductsData((prev) => {
                                            const productsData = [...prev];
                                            productsData[id] = {
                                                ...prev[id],
                                                product: {
                                                    ...c.product,
                                                    manager_id: c.manager_id,
                                                    price: c.price,
                                                    price_final: c.price_final,
                                                    total: c.total,
                                                    total_full: c.total_full,
                                                    additional_services: c.additional_services,
                                                    special_offers: c.special_offers,
                                                    discounts: c.discounts
                                                },
                                                discount_pks: c.discount_pks,
                                                manager: c.manager,
                                                manager_id: c.manager_id,
                                                refunded: c.refunded,
                                                has_bundles: c.has_bundles,
                                                has_gifts: c.has_gifts,
                                                has_discount_offers: c.has_discount_offers,
                                                has_additional_services: c.has_additional_services,
                                                id: c.id,
                                                user_id: c.user_id,
                                                user_type: c.user_type,
                                                product_id: c.product_id,
                                                quantity: c.quantity,
                                                warehouse: c.warehouse,
                                                purchase_quantity: c.purchase_quantity,
                                                discount_amount: Math.round(c.discount_amount),
                                                discount_absolute: c.discount_absolute,
                                                discount_bpm: c.discount_bpm,
                                                total: c.total,
                                                discount_percent: c.discount_percent,
                                                selected: c.selected,
                                                return_quantity: c.return_quantity,
                                                is_store_request: c.manager,
                                            };
                                            return productsData;
                                        })
                                    })
                                }

                            })
                            .catch(async (err) => await errorMessage(err, ''))
                    }
                }
            }

            await editOrder(orderID, parseDataSend).then(res => {
                navigate(`/app/order${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`);
                setLoad(false);
                setTotalPriceData({
                    total: res.data.total,
                    refunded: res.data.refunded,
                    price_final: res.data.price_final,
                });
                res?.data?.products?.map((c, id) => {
                    setProductsData((prev) => {
                        const productsData = [...prev];
                        productsData[id] = {
                            ...prev[id],
                            product: {
                                ...c.product,
                                manager_id: c.manager_id,
                                price: c.price,
                                price_final: c.price_final,
                                total: c.total,
                                total_full: c.total_full,
                                additional_services: c.additional_services,
                                special_offers: c.special_offers,
                            },
                            manager: c.manager,
                            manager_id: c.manager_id,
                            refunded: c.refunded,
                            has_bundles: c.has_bundles,
                            has_gifts: c.has_gifts,
                            has_additional_services: c.has_additional_services,
                            id: c.id,
                            user_id: c.user_id,
                            user_type: c.user_type,
                            product_id: c.product_id,
                            quantity: c.quantity,
                            warehouse: c.warehouse,
                            purchase_quantity: c.purchase_quantity,
                            discount_amount: Math.round(c.discount_amount),
                            discount_absolute: c.discount_absolute,
                            discount_pks: c.discount_pks,
                            discount_bpm: c.discount_bpm,
                            total: c.total,
                            discount_percent: c.discount_percent,
                            selected: c.selected,
                            return_quantity: c.return_quantity,
                            is_store_request: c.manager,
                        };
                        return productsData;
                    })
                })
                toast(`Заказ ${orderID} обновлен`, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
                .catch(async (err) => {
                    if(err?.response?.status === 401) {
                        await refreshToken(async () => {
                            await editOrder(orderID, parseDataSend).then(res => {
                                navigate(`/app/order${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`);
                                setLoad(false);
                                setTotalPriceData({
                                    total: res.data.total,
                                    refunded: res.data.refunded,
                                    price_final: res.data.price_final,
                                });
                                res?.data?.products?.map((c, id) => {
                                    setProductsData((prev) => {
                                        const productsData = [...prev];
                                        productsData[id] = {
                                            ...prev[id],
                                            product: {
                                                ...c.product,
                                                manager_id: c.manager_id,
                                                price: c.price,
                                                price_final: c.price_final,
                                                total: c.total,
                                                total_full: c.total_full,
                                                additional_services: c.additional_services,
                                                special_offers: c.special_offers,
                                            },
                                            manager: c.manager,
                                            manager_id: c.manager_id,
                                            refunded: c.refunded,
                                            has_bundles: c.has_bundles,
                                            has_gifts: c.has_gifts,
                                            has_additional_services: c.has_additional_services,
                                            id: c.id,
                                            discount_pks: c.discount_pks,
                                            user_id: c.user_id,
                                            user_type: c.user_type,
                                            product_id: c.product_id,
                                            quantity: c.quantity,
                                            warehouse: c.warehouse,
                                            purchase_quantity: c.purchase_quantity,
                                            discount_amount: Math.round(c.discount_amount),
                                            discount_absolute: c.discount_absolute,
                                            discount_bpm: c.discount_bpm,
                                            total: c.total,
                                            discount_percent: c.discount_percent,
                                            selected: c.selected,
                                            return_quantity: c.return_quantity,
                                            is_store_request: c.manager,
                                        };
                                        return productsData;
                                    })
                                })
                                toast(`Заказ ${orderID} обновлен`, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                            })
                        })
                    } else {
                        await errorMessage(err, '/api/admin/order/ PUT')
                    }
                })
                .finally(() => setLoad(false));
        } else {
            scrollIntoViewHelper(formProps?.formH?.errors);
            toast.error("Заполните все поля", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const applyHandler = async () => {
        if (formProps?.data?.fields?.discount_bpm !== 0
            && (orderEdit?.status_payment?.toString() === '1' || formProps?.data?.fields?.status_payment?.toString() === '1')
            && (orderEdit?.delivery_type?.toString() === '6' || formProps?.data?.fields?.delivery_type?.toString() === '6')
            && orderEdit?.status?.toString() === '4'
        ) {
            toast.error("Скасувати списання бонусів щоб продовжити", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            return
        }

        if(orderEdit?.order_comment?.text === '') {
            delete orderEdit.order_comment
        }

        if(!Object?.values(formProps?.formH?.errors)?.length) {
            setLoad(true);
            if(orderEdit?.order_comment?.text === null) {
                orderEdit['order_comment'] = null;
            }

            if(orderEdit?.delivery) {
                if(orderEdit?.delivery?.address?.street === null) {
                    orderEdit['delivery']['address'] = null;
                }
            }

            const parseDataSend = parseAll(orderEdit, true);

            if (!!parseDataSend?.products?.length) {
                const findDiscount = parseDataSend?.products?.find(item => {
                    if (item?.discount_amount && (item?.discount_amount > 0)) {
                        return item;
                    }
                    if (item?.discount_percent && (item?.discount_percent > 0)) {
                        return item;
                    }
                });

                if (findDiscount) {
                    await removeOrderCouponPks(formProps?.orderID)
                        .then((res) => {
                            const parseData = parseDataGet(res?.data, formProps?.orderID);

                            if (formProps?.setData) {
                                formProps?.setData(prev => {
                                    const temp = {...prev};

                                    temp.fields = parseData;

                                    return temp;
                                })
                            }

                        })
                        .catch(async (err) => await errorMessage(err, ''))
                }

                const findChangeQuantityProduct = parseDataSend?.products?.filter(item => item?.quantity);
                const findChangeAdditionalServicesProduct = parseDataSend?.products?.filter(item => !!item?.additional_services?.length);

                if (!!findChangeQuantityProduct?.length || !!findChangeAdditionalServicesProduct?.length) {

                    if (formProps?.data?.fields?.has_pks_discount) {
                        await removeOrderCouponPks(formProps?.orderID)
                            .then((res) => {
                                const parseData = parseDataGet(res?.data, formProps?.orderID);

                                if (formProps?.setData) {
                                    formProps?.setData(prev => {
                                        const temp = {...prev};

                                        temp.fields = parseData;

                                        return temp;
                                    })
                                }
                                if (!!res?.data?.products?.length) {
                                    res?.data?.products?.map((c, id) => {
                                        setProductsData((prev) => {
                                            const productsData = [...prev];
                                            productsData[id] = {
                                                ...prev[id],
                                                product: {
                                                    ...c.product,
                                                    manager_id: c.manager_id,
                                                    price: c.price,
                                                    price_final: c.price_final,
                                                    total: c.total,
                                                    total_full: c.total_full,
                                                    additional_services: c.additional_services,
                                                    special_offers: c.special_offers,
                                                    discounts: c.discounts
                                                },
                                                discount_pks: c.discount_pks,
                                                manager: c.manager,
                                                manager_id: c.manager_id,
                                                refunded: c.refunded,
                                                has_bundles: c.has_bundles,
                                                has_gifts: c.has_gifts,
                                                has_discount_offers: c.has_discount_offers,
                                                has_additional_services: c.has_additional_services,
                                                id: c.id,
                                                user_id: c.user_id,
                                                user_type: c.user_type,
                                                product_id: c.product_id,
                                                quantity: c.quantity,
                                                warehouse: c.warehouse,
                                                purchase_quantity: c.purchase_quantity,
                                                discount_amount: Math.round(c.discount_amount),
                                                discount_absolute: c.discount_absolute,
                                                discount_bpm: c.discount_bpm,
                                                total: c.total,
                                                discount_percent: c.discount_percent,
                                                selected: c.selected,
                                                return_quantity: c.return_quantity,
                                                is_store_request: c.manager,
                                            };
                                            return productsData;
                                        })
                                    })
                                }

                            })
                            .catch(async (err) => await errorMessage(err, ''))
                    }
                }
            }

            console.log('parseDataSend', parseDataSend)

            await editOrder(orderID, parseDataSend).then(res => {
                setLoad(false);
                setStatusChanges(filterStatusOrder(res.data.status_changes));
                setTotalPriceData({
                    total: res.data.total,
                    refunded: res.data.refunded,
                    price_final: res.data.price_final,
                });
                res?.data?.products?.map((c, id) => {
                    setProductsData((prev) => {
                        const productsData = [...prev];
                        productsData[id] = {
                            ...prev[id],
                            product: {
                                ...c.product,
                                manager_id: c.manager_id,
                                price: c.price,
                                price_final: c.price_final,
                                total: c.total,
                                total_full: c.total_full,
                                additional_services: c.additional_services,
                                special_offers: c.special_offers,
                            },
                            is_store_request: c.manager,
                            manager: c.manager,
                            manager_id: c.manager_id,
                            refunded: c.refunded,
                            has_bundles: c.has_bundles,
                            has_gifts: c.has_gifts,
                            has_additional_services: c.has_additional_services,
                            id: c.id,
                            user_id: c.user_id,
                            user_type: c.user_type,
                            product_id: c.product_id,
                            quantity: c.quantity,
                            warehouse: c.warehouse,
                            purchase_quantity: c.purchase_quantity,
                            discount_amount: Math.round(c.discount_amount),
                            discount_absolute: c.discount_absolute,
                            discount_pks: c.discount_pks,
                            discount_bpm: c.discount_bpm,
                            total: c.total,
                            discount_percent: c.discount_percent,
                            selected: c.selected,
                            return_quantity: c.return_quantity,
                        };
                        return productsData;
                    })
                })
                toast(`Заказ ${orderID} обновлен`, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                formH.setValue('order_comment.text', '');
                setData(prev => ({
                    ...prev,
                    fields: parseDataGet(res?.data, res?.data?.id)
                }));
                clearOrderEditData();
            })
                .catch(async (err) => {
                    if(err?.response?.status === 401) {
                        await refreshToken(async () => {
                            await editOrder(orderID, parseDataSend).then(res => {
                                setLoad(false);
                                setStatusChanges(filterStatusOrder(res.data.status_changes));
                                setTotalPriceData({
                                    total: res.data.total,
                                    refunded: res.data.refunded,
                                    price_final: res.data.price_final,
                                });
                                res?.data?.products?.map((c, id) => {
                                    setProductsData((prev) => {
                                        const productsData = [...prev];
                                        productsData[id] = {
                                            ...prev[id],
                                            product: {
                                                ...c.product,
                                                manager_id: c.manager_id,
                                                price: c.price,
                                                price_final: c.price_final,
                                                total: c.total,
                                                total_full: c.total_full,
                                                additional_services: c.additional_services,
                                                special_offers: c.special_offers,
                                            },
                                            manager: c.manager,
                                            manager_id: c.manager_id,
                                            refunded: c.refunded,
                                            has_bundles: c.has_bundles,
                                            has_gifts: c.has_gifts,
                                            has_additional_services: c.has_additional_services,
                                            id: c.id,
                                            user_id: c.user_id,
                                            user_type: c.user_type,
                                            product_id: c.product_id,
                                            quantity: c.quantity,
                                            warehouse: c.warehouse,
                                            purchase_quantity: c.purchase_quantity,
                                            discount_amount: Math.round(c.discount_amount),
                                            discount_absolute: c.discount_absolute,
                                            discount_pks: c.discount_pks,
                                            discount_bpm: c.discount_bpm,
                                            total: c.total,
                                            discount_percent: c.discount_percent,
                                            selected: c.selected,
                                            return_quantity: c.return_quantity,
                                            is_store_request: c.manager,
                                        };
                                        return productsData;
                                    })
                                })
                                toast(`Заказ ${orderID} обновлен`, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                                formH.setValue('order_comment.text', '');
                                setData(prev => ({
                                    ...prev,
                                    fields: parseDataGet(res?.data, res?.data?.id)
                                }));
                                clearOrderEditData();
                            })
                        })
                    } else {
                        await errorMessage(err, '/api/admin/order/ PUT')
                    }
                })
                .finally(() => setLoad(false));
            // localStorage.setItem('apply', 'on');
            // ps.publish(EDIT.save);
        } else {
            scrollIntoViewHelper(formProps?.formH?.errors);
            toast.error("Заполните все поля", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const getOrderStatusName = (id) => formProps?.data?.statuses && formProps?.data?.statuses?.find((el) => id?.toString() === el.id?.toString());

    useEffect(() => {
        setOrderStatus(dataFields?.status);
        setOldOrderStatus(dataFields?.status);

        onChange('status', dataFields?.status)
    }, [dataFields?.status]);

    useEffect(() => {

        if(!!dataFields?.status_changes?.length) {
            if(!!localStorage?.getItem('roles')?.length) {
                if (JSON.parse(localStorage?.getItem('roles'))?.some(itemRole => ['admin', 'super-admin']?.includes(itemRole))) {
                    if (!!productsData?.length) {
                        setStatusChanges(filterStatusOrder(dataFields?.status_changes));
                    } else {
                        const findCanceledStatus = dataFields?.status_changes?.find(status => status?.status_to === 17);
                        setStatusChanges([findCanceledStatus]);
                    }
                } else {
                    if (!!productsData?.length) {
                        const parseStatus = dataFields?.status_changes?.filter(status => ![28, 29]?.includes(status?.status_to))
                        setStatusChanges(filterStatusOrder(parseStatus));
                    } else {
                        const findCanceledStatus = dataFields?.status_changes?.find(status => status?.status_to === 17);
                        setStatusChanges([findCanceledStatus]);
                    }
                }
            }
        }

    }, [dataFields, productsData]);

    useEffect(() => {
        if((dataFields?.status !== orderStatus) && orderStatus) {
            setOrderStatusCheck("on");
        } else {
            setOrderStatusCheck('');
        }

        switch (orderStatus) {
            case 3:
            case 5:
            case 6:
            case 17:
            case 22:
            case 23:
            case 33: {
                setRequiredFields(false);
                break;
            }
        }

        if(orderStatus?.toString() === '4') {
            setRequiredFields(true);
        }
    }, [orderStatus]);

    useEffect(() => {
        if (dataFields?.user?.email && !dataFields?.user_info?.email) {
            const saveEmail = async () => {
                await editOrder(orderID, {order_user_info: { email: dataFields?.user?.email, id: dataFields?.user_info?.id }})
                    .then(res => window.location.reload())
                    .catch((err) => errorMessage(err, '/api/admin/order/ PUT'))
                    .finally(() => setLoad(false));
            }

            saveEmail()
        }
    }, [dataFields])

    return (
        <>
            <div>
                <div className={s.breadcrumbs__wrapper}>
                    <div>
                        <Breadcrumbs {...breadcrumbs}/>
                        <div className={s.title}>
                            <div>{formProps?.data?.fields?.id ? `Samsung: Заказ #${formProps?.data?.fields?.id} от ${getTZtoDate(formProps?.data?.fields?.created_at, true)}` : 'Новый заказ'}</div>
                            <div onClick={handleCopyText}>
                                <img src={icon_copy} alt="Copy order image icon"/>
                            </div>
                            {copyShow && <div className={s.copy}>Скопировано!</div>}
                        </div>
                    </div>
                    {!checkEdit ? (
                        <div className={s.no_edit}>
                            <div className={s.no_edit__item}>
                                <div className={s.no_edit__block}>
                                    <img src={icon_lock} alt="icon lock" />
                                    <div>Документ заблокирован</div>
                                </div>
                            </div>
                            <div className={s.no_edit__item}>
                                <div>Редактирует: <b>{editManager?.reservedBy?.name}</b></div>
                            </div>
                            {/*<div className={s.no_edit__item}>*/}
                            {/*    <div>Просматривают: <b>{editManager?.visited}</b></div>*/}
                            {/*</div>*/}
                        </div>
                    ) : dataFields?.id && (
                        <div className={s.order_edited}>
                            <div className={s.order_edited__block}>
                                <div className={s.order_edited__title}>Отредактировано:</div>
                                <div className={s.order_edited__date}>{formProps?.data?.fields?.updated_at && getTZtoDate(formProps?.data?.fields?.updated_at, true)}</div>
                            </div>
                            <div className={s.order_edited__box}>
                                <input type="hidden" name="status_top" defaultValue={orderStatus} ref={form.register} />
                                <input type="hidden" name="status_top_check" defaultValue={orderStatusCheck} ref={form.register} />
                                <button
                                    type="button"
                                    className={cn(s.btn__status, {
                                        [s.btn__status__disable]: !formProps?.data?.fields?.status_transition?.is_allowed
                                    })}
                                    onClick={() => !!formProps?.data?.fields?.status_transition?.is_allowed && setOrderStatusShow(!orderStatusShow)}
                                >
                                    {getOrderStatusName(orderStatus)?.name}
                                    <img src={icon_arrow_down} alt="Arrow down icon"/>
                                </button>
                                {!formProps?.data?.fields?.status_transition?.is_allowed && (
                                    <div>{formProps?.data?.fields?.status_transition?.status_comment}</div>
                                )}
                                {orderStatusShow && (
                                    <div className={s.order_status}>
                                        <div onClick={() => handleOrderStatus(dataFields?.status)}>{getOrderStatusName(dataFields?.status)?.name}</div>
                                        {!!statusChanges.length && statusChanges.map((item) => {
                                            const nameStatus = formProps?.data?.statuses?.find((order_status) => order_status.id === item?.status_to)?.name;

                                            if(orderEdit?.isBPMDiscount?.status && nameStatus !== 'Аннулирован') {
                                              const err = {response: {status: "customBPNDiscount"}}
                                              return (
                                                <div onClick={() => errorMessage(err, 'Замовлення БОПИС, приберіть списання бонусів з товарів щоб продовжити!')}>{nameStatus}</div>
                                              )
                                            } else {
                                              return (
                                                <div key={uid(item.status_to)} onClick={() => {
                                                  handleOrderStatus(item.status_to);
                                                }}>{nameStatus}</div>
                                              )
                                            }
                                        })}

                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                {orderStatusShow && <div onClick={() => setOrderStatusShow(false)} className={s.order_status_close} />}
            </div>
            <div className={s.footer}>
                <div>
                    <Link to={`/app/order${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`}>
                        <img src={icon_arrow_back} alt="Arrow back icon"/>
                        Назад
                    </Link>
                </div>
                {editBtn && (
                    <div className={s.buttons__wrapper}>
                        <div className={s.buttons__group}>
                            <div className={s.button__block}>
                                <Button green onClick={applyHandler} disabled={!checkEdit ? !checkEdit : !Object.values(orderEdit).length}>
                                    <img src={icon_accept} alt="Accept icon"/>
                                    Применить
                                </Button>
                            </div>
                            <div className={s.button__block}>
                                <Button green onClick={saveHandler} disabled={!checkEdit ? !checkEdit : !Object.values(orderEdit).length}>
                                    <img src={icon_save} alt="Save icon"/>
                                    Сохранить
                                </Button>
                            </div>
                            {/*<div className={s.button__block}>*/}
                            {/*    <Button purple onClick={() => setActiveShipment(true)} disabled={!checkEdit}>*/}
                            {/*        <img src={icon_shipment} alt="Shipment icon"/>*/}
                            {/*        Отгрузка*/}
                            {/*    </Button>*/}
                            {/*</div>*/}
                            <div className={s.button__block}>
                                <Link to={`document`}>
                                    <Button purple disabled={!checkEdit}>
                                        <img src={icon_document} alt="Document icon"/>
                                        Документы
                                    </Button>
                                </Link>
                            </div>
                            <div className={s.button__block}>
                                <Button purple onClick={() => setActiveSendSMS(true)} disabled={!checkEdit}>
                                    <img src={icon_email} alt="SMS icon"/>
                                    CMC
                                </Button>
                            </div>
                            <div className={s.button__block}>
                                <Button purple onClick={() => setActiveTtn(true)} disabled={!checkEdit}>
                                    <img src={icon_ttn} alt="TTN icon"/>
                                    TTH
                                </Button>
                            </div>
                        </div>
                        <div className={s.button__box}>
                            <div className={`${s.buttons__show} ${openButton ? s.active_buttons : ''}`}>
                                <div>
                                    <Button purple onClick={() => setActiveLogOnOrder(true)} disabled={!checkEdit}>
                                        <img src={icon_file_log} alt="Log icon"/>
                                        Лог
                                    </Button>
                                </div>
                                <div>
                                    <Button purple onClick={() => setActiveFiscalReceipt(true)} disabled={!checkEdit}>
                                        <img src={icon_check} alt="Check icon"/>
                                        Чек
                                    </Button>
                                </div>
                                {dataFields?.status === 3 && (
                                    <div>
                                        <Button purple onClick={() => setActiveInvoice(true)} disabled={!checkEdit}>
                                            Інвойс
                                        </Button>
                                    </div>
                                )}
                                <div>
                                    <Button purple onClick={() => setActivePrivateLog(true)} disabled={!checkEdit}>
                                        <img src={icon_file_log} alt="Log icon"/>
                                        Лог (Приват)
                                    </Button>
                                </div>
                                {!!dataFields?.status_changes?.length && [28, 29].includes(dataFields?.status_changes?.[0]?.status) && (
                                    <div>
                                        <Button purple onClick={() => setActiveReturnProduct(true)} disabled={!checkEdit}>
                                            <img src={icon_return_product} alt="Return product icon"/>
                                            Повернення
                                        </Button>
                                    </div>
                                )}
                            </div>
                            <div className={`${s.button__open} `} onClick={handleOpenButton}>
                                <img className={openButton ? s.active_arrow : ''} src={icon_arrow_right} alt="Arrow right icon"/>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {load && <Loader />}
        </>
    );
};

export default TopPanel;