import React, {Fragment, useEffect, useMemo, useState} from "react";
import { getData, parseDataGet, parseDataSend, edit } from "./api";
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import { Helmet } from "react-helmet";
import TopPagination from "../../components/common/TableListViewNew/TopPagination";
import TopNavTabs from "../../components/ui/TopNavTabsNew/TopNavTabs";
import TopPanelChild from "../../components/common/TopPanelNew/TopPanelChild";
import Button from "../../components/ui/Button";
import icon_save from "../../assets/icons/icon_save.svg";
import IconApply from "../../components/icon/IconApply";
import CheckboxSwitch from "../../components/ui/CheckboxSwitch";
import s from "./DiscountPks.module.scss";
import RSelect from "../../components/ui/RSelect";
import s_Select from "../OrderEditView/fakeData/orderStyle.module.scss";
import InputWrapper from "../../components/ui/InputWrapper";
import s_Input from "../../components/ui/InputWrapper/InputWrapper.module.scss";
import { INPUT_MAX_LENGTH } from "../../const/view";
import icon_delete from "../../assets/icons/icon_delete.svg";
import useEdit from "../../lib/crud/useEdit";
import { useForm } from "react-hook-form";
import Form from "../../components/ui/FormEditView/Form";
import {PubSub as ps} from "pubsub-js";
import {EDIT} from "../../const/pubsub";
import cn from "classnames";
import { VALIDATION } from "../../const/text";

const DiscountPks = () => {
    const formH = useForm();
    const {
        register,
        errors,
        handleSubmit,
        reset,
        watch,
    } = formH;

    const { load, data, submitHandler } = useEdit({
        targetID: 4,
        handleSubmit,
        api: {
            edit,
            get: getData,
        },
        parse: {
            get: parseDataGet,
            send: parseDataSend,
        },
        customTargetID: true
    });

    const [specOfferSelected, setSpecOfferSelected] = useState(null);
    const [specOfferList, setSpecOfferList] = useState([]);
    const [categoryData, setCategoryData] = useState([]);

    const [conditions, setConditions] = useState([]);

    const handleAddCondition = () => {
        setConditions((prev) => {
            if(prev.length !== 0) {
                return [...prev, {
                    attribute: "product|jde",
                    attribute_type: "text",
                    value: "",
                    operator: '==',
                    action_type: "by_percent",
                    discount_amount: ''
                }];
            } else {
                return [{
                    attribute: "product|jde",
                    attribute_type: "text",
                    value: "",
                    operator: '==',
                    action_type: "by_percent",
                    discount_amount: ''
                }];
            }
        });
    };

    const removeHandler = (el) => {
        setConditions(prev => {
            const temp = [...prev];
            const index = temp.indexOf(el);
            temp.splice(index, 1);
            return temp;
        })
    };

    function applyHandler() {
        localStorage.setItem('apply', 'on');
        ps.publish(EDIT.save);
    }

    useEffect(() => {
        if (!!data?.special_offers?.length) {
            setSpecOfferList(data?.special_offers);
        }
    }, [data?.special_offers]);

    useEffect(() => {
        if(!!data?.fields?.conditions_merge?.length) {
            setConditions(data.fields?.conditions_merge);
        }
    }, [data?.fields?.conditions_merge]);

    useEffect(() => {
        if(!!data?.categories?.length) {
            setCategoryData(data.categories);
        }
    }, [data]);

    useEffect(() => {
        if(!!data?.fields?.special_offer_excepted_ids?.length && !!specOfferList?.length) {
            setSpecOfferSelected(() => {
                const result = [];

                specOfferList?.forEach((item) => {
                    if (data?.fields?.special_offer_excepted_ids?.find(offer => offer === item.id)) {
                        result.push(item)
                    }
                });

                return result;
            });
        }
    }, [data?.fields?.special_offer_excepted_ids, specOfferList]);

    return (
        <div style={{ margin: 0 }}>
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Discount PKS list`}/>
            <Form attrs={{
                onSubmit: handleSubmit(submitHandler),
            }}
            >
                <button type="submit" className="d-hide" />

                <TopPanelChild
                    title="Налаштування знижок за ПКС"
                    breadcrumbs={{
                        items: [
                            {
                                title: "Головна",
                                url: "/app",
                                permissionName: 'admin.cart-rules.type.create'
                            },
                            {
                                title: "Налаштування знижок за ПКС",
                                permissionName: 'admin.cart-rules.type.create'
                            }
                        ]
                    }}
                    childrenBtnSave={(
                        <div style={{ display: 'flex', gap: 16 }}>
                            <Button onClick={() => window.location.reload()} borderClear>Відмінити</Button>
                            {data?.fields?.id && (
                                <>
                                    <Button onClick={applyHandler} border>
                                        <IconApply />
                                        Застосувати
                                    </Button>
                                    <Button onClick={applyHandler} purple>
                                        <img src={icon_save} alt="Save icon"/>
                                        Зберегти
                                    </Button>
                                </>
                            )}
                        </div>
                    )}
                    style={{ marginBottom: 10 }}
                    btnEdit
                />

                <TopPagination
                    tabs={(
                        <TopNavTabs tabs={[
                            {
                                title: 'Samsungshop',
                                url: '/app/discount-pks',
                                permissionName: 'admin.cart-rules.type.create',
                                isActive: true
                            }
                        ]}
                        />
                    )}
                    pagination={(
                        <CheckboxSwitch
                            id="im-is-active"
                            name="is_active"
                            label="Статус активності"
                            formH={formH}
                            defaultChecked={data?.fields?.is_active}
                        />
                    )}
                    style={{
                        marginTop: 0
                    }}
                />

                <div className={s.wrapper}>
                    <div className={s.left}>
                        <CheckboxSwitch
                            name="has_student_tickets"
                            id="ps-has-student-tickets"
                            label="Студентський квиток"
                            fontWeight={400}
                            formH={formH}
                            defaultChecked={data?.fields?.has_student_tickets}
                        />

                        <CheckboxSwitch
                            name="is_special_offer_compatible"
                            id="ps-is-special-offer-compatible"
                            label="Сумісний із спец. пропозиціями"
                            fontWeight={400}
                            formH={formH}
                            defaultChecked={data?.fields?.is_special_offer_compatible}
                        />

                        <div>
                            <div className={s.title_spec}>Виключення</div>
                            <RSelect
                                props={{
                                    value: specOfferSelected,
                                    onChange: (opt) => {setSpecOfferSelected(opt)},
                                    placeholder: 'Виключення:',
                                    options: specOfferList,
                                    isMulti: true,
                                    getOptionLabel: (opt) => opt.title_ua,
                                    getOptionValue: (opt) => opt.id
                                }}
                            />

                            {!!specOfferSelected?.length && specOfferSelected?.map((item, index) =>
                                <input
                                    key={`special-offer-excepted-ids-item-hidden-${index}`}
                                    type="hidden"
                                    ref={register}
                                    name={`special_offer_excepted_ids[${index}]`}
                                    value={item.id}
                                />
                            )}
                        </div>
                    </div>
                    <div className={s.conditions__wrapper}>
                        {!!conditions?.length && conditions.map((item, index) => {
                            return (
                                <div
                                    className={cn(s.conditions__block, {
                                        [s.item_border_red]: formH?.errors?.conditions?.[index]?.value
                                    })}
                                    key={`discount-pks-condition-item-${item?.id || index}`}
                                >
                                    {/* conditions[].attribute */}
                                    <div className={s_Select.select__inner} style={{ margin: 0, width: "20%" }}>
                                        <div className={s_Select.select__wrapper}>
                                            <select
                                                className={s_Select.select}
                                                id={`ps-condition-attribute-${item?.id || index}`}
                                                value={item?.attribute}
                                                name={`conditions[${index}].attribute`}
                                                onChange={(e) => {
                                                    setConditions(prev => {
                                                        const temp = [...prev];
                                                        temp[index].attribute = e.target.value;

                                                        const findCondition = data?.conditions['condition_attributes']?.find(itemCond => itemCond?.key === 'product')?.children?.find(itemChild => itemChild.key === e.target.value);

                                                        if (findCondition) {
                                                            temp[index].attribute_type = findCondition?.type;
                                                        }

                                                        return temp;
                                                    })
                                                }}
                                                ref={register}
                                            >
                                                {!!data?.conditions['condition_attributes']?.length && data?.conditions['condition_attributes']?.map((condit, n) => {

                                                    if (condit?.key === 'product') {
                                                        return condit?.children?.map((it, number) => {
                                                            if (it.key === "product|price_final") {
                                                                return null;
                                                            }
                                                            return (
                                                                <option value={it.key} key={`option-condition-attribute-${number}`}>
                                                                    {it.label}
                                                                </option>
                                                            );
                                                        });
                                                    }

                                                    return null;
                                                })}
                                            </select>
                                            <label
                                                className={s_Select.label}
                                                htmlFor={`ps-condition-attribute-${item?.id || index}`}
                                            >
                                                Об'єкт
                                            </label>
                                        </div>
                                    </div>

                                    {/* conditions[].attribute_type */}
                                    <input
                                        name={`conditions[${index}].attribute_type`}
                                        value={item?.attribute_type}
                                        type="hidden"
                                        ref={register}
                                    />

                                    {/* conditions[].operator */}
                                    {!!data?.conditions?.['condition_attributes']?.length && data?.conditions['condition_attributes']?.map((condit, num) => {
                                        if (condit?.key === 'product') {
                                            const findItem = condit?.children?.find(it => it.key === item.attribute);

                                            if(findItem) {

                                                return (
                                                    <Fragment key={`condint-operator-${num}`}>
                                                        <div className={s_Select.select__inner} style={{ margin: 0, width: "20%" }}>
                                                            <div className={s_Select.select__wrapper}>
                                                                <select
                                                                    className={s_Select.select}
                                                                    name={`conditions[${index}].operator`}
                                                                    id={`ps-conditions-${index}-value`}
                                                                    ref={register}
                                                                    value={item?.operator || data?.conditions['condition_operators']?.[findItem.type]?.[0]?.operator}
                                                                    onChange={(e) => {
                                                                        setConditions(prev => {
                                                                            const temp = [...prev];
                                                                            temp[index].operator = e.target.value;
                                                                            return temp;
                                                                        })
                                                                    }}
                                                                >
                                                                    <option value="">Оберіть дію</option>
                                                                    {!!Object.keys(data?.conditions?.['condition_operators'])?.length && data?.conditions['condition_operators']?.[findItem.type]?.map((operator, n) => {
                                                                        return (
                                                                            <option value={operator.operator} key={`condition-operator-${n}`}>
                                                                                {operator.label}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                                <label className={s_Select.label} htmlFor={`ps-conditions-${index}-value`}>Дія</label>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                )
                                            }
                                        }

                                        return null;
                                    })}

                                    {['product|selection', 'product|category_ids']?.includes(item?.attribute) ? (
                                        <div className={s_Select.select__inner} style={{ margin: 0, width: "20%" }}>
                                            <div className={s_Select.select__wrapper}>
                                                <select
                                                    name={`conditions[${index}].value`}
                                                    className={cn(s_Select.select, {
                                                        [s_Select.select_error]: formH?.errors?.conditions?.[index]?.value
                                                    })}
                                                    id={`ps-conditions-value-${index}`}
                                                    ref={formH.register({
                                                        required:  VALIDATION.select.required
                                                    })}
                                                    value={item?.value}
                                                    onChange={(e) => {
                                                        setConditions(prev => {
                                                            const temp = [...prev];
                                                            temp[index].value = e.target.value;
                                                            return temp;
                                                        })
                                                    }}
                                                >
                                                    {(!!categoryData.length && (item?.attribute === 'product|category_ids')) && categoryData?.map((cat, n) => {
                                                        if (!!cat?.children?.length) {
                                                            const mapItem = cat?.children?.map((it, number) => {
                                                                return <option value={it.value} key={`category-item-${number}`}>{it.label}</option>;
                                                            });
                                                            return <optgroup label={cat.label} key={`optgroup-condition-attribute-${n}`}>
                                                                {mapItem}
                                                            </optgroup>
                                                        } else {
                                                            return <option value={cat.value} key={`category-item-${n}`}>{cat.label}</option>;
                                                        }

                                                    })}

                                                    {(!!data?.selections?.length && (item?.attribute === 'product|selection')) && data?.selections?.map((sel, n) => {
                                                        return <option value={sel.id} key={`category-item-${n}`}>{sel.title}</option>;
                                                    })}
                                                </select>
                                                <label className={s_Select.label} htmlFor={`ps-discount-conditions-action-type-${index}`}>
                                                    {item?.attribute === 'product|category_ids' && 'Категорії'}
                                                    {item?.attribute === 'product|selection' && 'Вибірки'}
                                                </label>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            {/* conditions[].value */}
                                            <InputWrapper
                                                label="JDE"
                                                id={`ps-conditions-value-text-${index}`}
                                                style={{ margin: 0, width: "20%" }}
                                            >
                                                <input
                                                    className={cn(s_Input.input, {
                                                        [s_Input.input_error]: formH?.errors?.conditions?.[index]?.value,
                                                    })}
                                                    id={`ps-conditions-value-text-${index}`}
                                                    type="text"
                                                    name={`conditions[${index}].value`}
                                                    placeholder="Введіть значення"
                                                    maxLength={INPUT_MAX_LENGTH}
                                                    value={item?.value}
                                                    ref={formH.register({
                                                        required: VALIDATION.req.required,
                                                        validate: v => conditions.filter(f => f['value'] === v).length <= 1 || 'Значення з таким JDE вже існує!',
                                                    })}
                                                    onChange={(e) => {
                                                        setConditions(prev => {
                                                            const temp = [...prev];
                                                            temp[index].value = e.target.value;
                                                            return temp;
                                                        })
                                                    }}
                                                />
                                            </InputWrapper>
                                        </>
                                    )}

                                    {/* discount_conditions[].action_type */}
                                    <div className={s_Select.select__inner} style={{ margin: 0, width: "20%", maxWidth: '200px' }}>
                                        <div className={s_Select.select__wrapper}>
                                            <select
                                                name={`discount_conditions[${index}].action_type`}
                                                className={cn(s_Select.select, {
                                                    'is-error': formH.errors?.discount_conditions?.[index]?.action_type,
                                                })}
                                                id={`ps-discount-conditions-action-type-${index}`}
                                                value={item.action_type}
                                                onChange={(e) => {
                                                    setConditions(prev => {
                                                        const temp = [...prev];
                                                        temp[index].action_type = e.target.value;
                                                        return temp;
                                                    })
                                                }}
                                                ref={formH.register({
                                                    required: VALIDATION.select.required,
                                                })}
                                            >
                                                {data && data?.action_types?.filter(type => ["by_percent_final","by_percent"].includes(type.key))?.map((type) => {
                                                    return (
                                                        <option
                                                            key={`discount-conditions-item-${index}-${type.sort}`}
                                                            value={type.key}
                                                            selected={item?.action_type === type.key}
                                                        >
                                                            {type.name}
                                                        </option>
                                                    )
                                                })}
                                            </select>
                                            <label className={s_Select.label} htmlFor={`ps-discount-conditions-action-type-${index}`}>Тип знижки</label>
                                        </div>
                                    </div>

                                    {/* discount_conditions[].discount_amount */}
                                    <InputWrapper
                                        label="Розмір знижки, %"
                                        id={`ps-conditions-value-text-${index}`}
                                        style={{ margin: 0, width: "20%" }}
                                    >
                                        <input
                                            name={`discount_conditions[${index}].discount_amount`}
                                            className={cn(s_Input.input, {
                                                [s_Input.input_error]: formH.errors?.discount_conditions?.[index]?.discount_amount,
                                            })}
                                            type="text"
                                            id={`ps-discount-conditions-${index}-discount-amount`}
                                            placeholder="Розмір знижки, %"
                                            value={item?.discount_amount}
                                            ref={formH.register({
                                                required: VALIDATION.req.required,
                                            })}
                                            maxLength={INPUT_MAX_LENGTH}
                                            onChange={(e) => {
                                                setConditions(prev => {
                                                    const temp = [...prev];
                                                    temp[index].discount_amount = e.target.value;
                                                    return temp;
                                                })
                                            }}
                                        />
                                    </InputWrapper>

                                    <button
                                        type="button"
                                        className={s.btn_danger}
                                        onClick={() => removeHandler(item)}
                                    >
                                        <img src={icon_delete} alt="icon delete" />
                                    </button>
                                </div>
                            )
                        })}

                        <Button purple onClick={handleAddCondition}>Добавити</Button>
                    </div>
                </div>
            </Form>
        </div>
    )
};

export default DiscountPks;
