import React from "react";
import Form from "../../../components/ui/FormEditView/Form";
import Loader from "../../../components/ui/Loader";
import TableHead from "../../../components/common/TableListViewNew/TableHead";
import TableListViewTable from "../../../components/common/TableListViewNew/Table";
import IconLinkChain from "../../../components/icon/iconLinkChain";
import IconIncomingCall from "../../../components/icon/iconIncomingCall";
import IconOutgoingCall from "../../../components/icon/iconOutgoingCall";
import IconMissedCall from "../../../components/icon/iconMissedCall";
import IconDroppedCall from "../../../components/icon/iconDroppedCall";
import getTZtoDate from "../../../lib/getTZtoDate";

const TabCalls = ({index = 4, formProps, callsHistory, loadCalls}) => {
    function submitHandler(e) {
        formProps.tabsForm.validate(e, formProps.submitHandler);
    }

    const emptyDataStyles = {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        border: '0.05rem solid rgb(216, 223, 236)',
        background: 'white',
        height: '45px',
        borderBottomRightRadius: '30px',
        borderBottomLeftRadius: '30px',
        alignItems: 'end',
        textAlign: 'center',
        position: 'absolute',
        top: '-15px',
        paddingBottom: '5px',
    };

    const getPhoneIcon = (call) => {
        if(!call?.billsec && !call?.internal_phone) {
            return <IconDroppedCall title={"Пропущеный"}/>
        } else if(!call?.billsec && call?.internal_phone) {
            return <IconMissedCall title={"Недозвон клиенту"}/>
        } else if(call?.type === 'incoming') {
            return <IconIncomingCall title={"Входящий"}/>
        } else {
            return <IconOutgoingCall title={"Исходящий"}/>
        }
    }

    /* eslint-disable */
    return (
        <form
            style={formProps.tabsForm.getDStyle(index)}
            onSubmit={submitHandler}
        >
            <Form Tag="div">

                <div style={{ width: '600px'}}>
                    <TableListViewTable >
                        <TableHead
                            arrayChildren={[
                                'Дата та час', 'Вхідні / Вихідні', "Менеджер", 'Лінк'
                            ]}
                            attrs={{style: {margin: 0}}}
                            showAction={false}
                        />
                        {loadCalls ? <Loader /> :
                        !!callsHistory?.length && (
                            <tbody>
                            {callsHistory?.map((e, index, i) => (
                                <tr key={index}>
                                    <td style={{ width: '200px', backgroundColor: index % 2 === 1 ? '#edeef0' : '#fff', padding: "15px 12px" }}>
                                            <span style={{fontSize: "13px", fontWeight: "bold"}}>
                                                 {e?.starts_at ? getTZtoDate(e?.starts_at, true): '---'}
                                            </span>
                                    </td>
                                    <td style={{textAlign: "center", width: '200px', backgroundColor: index % 2 === 1 ? '#edeef0' : '#fff', padding: "15px 12px" }}>
                                            <span>
                                                {getPhoneIcon(e)}
                                            </span>
                                    </td>
                                    <td style={{width: '200px', backgroundColor: index % 2 === 1 ? '#edeef0' : '#fff', padding: "15px 12px" }}>
                                            <span>
                                                {e?.manager ? e?.manager.name
                                                  : e.internal_phone ? e.internal_phone : '---' }
                                            </span>
                                    </td>
                                    <td style={{textAlign: "center", width: '70px', backgroundColor: index % 2 === 1 ? '#edeef0' : '#fff', padding: "15px 12px" }}>
                                            <span>
                                                {e?.record_link && <a href={e?.record_link} target="_blank" rel="noopener noreferrer">
                                                    <IconLinkChain color="primary"/>
                                                </a> || '---'}
                                            </span>
                                    </td>
                                </tr>
                            ))}
                            </tbody>)}
                    </TableListViewTable>
                    {!loadCalls && !callsHistory?.length && <div style={{position: "relative"}}>
                        <div style={emptyDataStyles}>Немає данних</div>
                    </div>}
                </div>
            </Form>

            {formProps.load && <Loader/>}
        </form>
    );
};

export default TabCalls;
