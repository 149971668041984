import React, { Fragment, useEffect, useState } from 'react';
import InputRadio from "../../../../components/ui/InputRadio";
import s from "./Conditions.module.scss";
import s_Block from "../../PromocodeSeriesEditView.module.scss";
import cn from "classnames";
import { VALIDATION } from "../../../../const/text";
import { INPUT_MAX_LENGTH } from "../../../../const/view";
import TreeCustom from "../TreeCustom";
import Button from "../../../../components/ui/Button";
import icon_delete from "../../../../assets/icons/icon_delete.svg";
import s_Select from "../../../OrderEditView/fakeData/orderStyle.module.scss";
import InputWrapper from "../../../../components/ui/InputWrapper";
import s_Input from "../../../../components/ui/InputWrapper/InputWrapper.module.scss";

const Conditions = ({ activeTab, data, formH, conditions, setConditions  }) => {
    const [conditionsType, setConditionsType] = useState('1');

    const [categoryData, setCategoryData] = useState([]);

    const handleOnChange = (e, index, name, setArray) => {
        setArray(prev => {
            const result = [...prev];
            result[index][name] = e.target.value;
            return [...result];
        });
    };

    const handleAddCondition = () => {
        setConditions((prev) => {
            if(prev.length !== 0) {
                return [...prev, {attribute: "cart|total", attribute_type: "", value: ""}];
            } else {
                return [{attribute: "cart|total", attribute_type: "", value: ""}];
            }
        });
    };

    const removeHandler = (el, setArray) => {
        setArray(prev => {
            const temp = [...prev];
            const index = temp.indexOf(el);
            temp.splice(index, 1);
            return temp;
        })
    };

    useEffect(() => {
        formH.setValue('condition_type', conditionsType)
    }, [conditionsType])

    useEffect(() => {
        if(data?.fields?.conditions) {
            setConditions(data?.fields?.conditions)
        }

        if(data?.fields?.condition_type) {
            setConditionsType(data?.fields?.condition_type?.toString())
        }

        if(data?.categories) {
            setCategoryData(data.categories);
        }
    }, [data]);

    return (
        <div style={{
            display: activeTab === 0 ? 'block' : 'none',
            height: activeTab === 0 ? 'auto' : 0,
        }}>
            {/* condition_type */}
            <div className={s.type}>
                <div className={s.type__label}>Тип умови:</div>
                <div className={`${s.condition__block} ${s.condition__radio}`}>
                    <InputRadio
                        name="condition_type"
                        id="condition-type-all"
                        label="Всі умови вірні"
                        formH={formH}
                        valueInput={'1'}
                        onChange={() => setConditionsType('1')}
                        value={conditionsType === '1'}
                        defaultChecked
                    />
                    <InputRadio
                        name="condition_type"
                        id="condition-type-some"
                        label="Одна з умов вірна"
                        formH={formH}
                        valueInput={'2'}
                        onChange={() => setConditionsType('2')}
                        value={conditionsType === '2'}
                    />
                </div>
            </div>

            {conditions?.length !== 0 && conditions.map((item, index) => {
                let typeWatch = '';

                return (
                    <div className={s_Block.conditions__block} key={`condition-item-id-${index}`}>
                        {/* conditions[].attribute */}
                        <div style={{ width: "25%" }}>
                            <div className={s_Select.select__inner}>
                                <div className={s_Select.select__wrapper}>
                                    <select
                                        className={cn(s_Select.select, {
                                            'is-error': formH?.errors?.conditions?.[index]?.attribute,
                                        })}
                                        id={`ps-condition-attribute-${index}`}
                                        defaultValue={item?.attribute}
                                        onChange={e => handleOnChange(e, index, 'attribute', setConditions)}
                                    >
                                        {data?.conditions && data?.conditions['condition_attributes']?.map((condit, n) => {
                                            const mapItem = condit?.children?.map((it, number) => {
                                                return <option value={it.key} key={`option-condition-attribute-${number}`} selected={item.attribute === it.key}>{it.label}</option>;
                                            });
                                            return <optgroup label={condit.label} key={`optgroup-condition-attribute-${n}`}>
                                                {mapItem}
                                            </optgroup>
                                        })}
                                    </select>
                                    <label className={s_Select.label} htmlFor={`ps-condition-attribute-${index}`}>Об'єкт</label>
                                </div>
                                    {formH?.errors?.conditions?.[index]?.attribute && <p className="form-input-hint">
                                        {formH?.errors?.conditions[index]?.attribute?.message}
                                    </p>}
                                    <input
                                        name={`conditions[${index}].attribute`}
                                        value={conditions[index].attribute}
                                        type="hidden"
                                        ref={formH.register({
                                            required: VALIDATION.select.required,
                                        })}
                                    />
                            </div>
                        </div>

                        {/* conditions[].operator */}
                        <div style={{ width: "25%" }}>

                            {data && data?.conditions['condition_attributes']?.map((condit, num) => {
                                const findItem = condit?.children?.find(it => it.key === item.attribute);
                                if(findItem) {
                                    typeWatch = findItem.type;
                                    return (
                                        <Fragment key={`condint-operator-${num}`}>
                                            <div className={s_Select.select__inner}>
                                                <div className={s_Select.select__wrapper}>
                                                    <select
                                                        className={cn(s_Select.select, {
                                                            'is-error': formH.errors?.conditions?.[index]?.operator,
                                                        })}
                                                        name={`conditions[${index}].operator`}
                                                        id={`ps-conditions-${index}-value`}
                                                        ref={formH.register({
                                                            required: VALIDATION.select.required,
                                                        })}
                                                        defaultValue={data?.conditions['condition_operators']?.[findItem.type]?.[0]?.operator}
                                                        onChange={e => handleOnChange(e, index, 'operator', setConditions)}
                                                    >
                                                        {/*<option value="">Выберите действие</option>*/}
                                                        {data && data?.conditions['condition_operators']?.[findItem.type]?.map((operator, n) => {
                                                            if (findItem.type !== "multiselect") {
                                                                switch (operator.operator) {
                                                                    case "[]": {
                                                                        return null;
                                                                    }
                                                                    case "![]": {
                                                                        return null;
                                                                    }
                                                                }
                                                            }
                                                            return <option value={operator.operator} key={`condition-operator-${n}`} selected={item.operator === operator.operator}>{operator.label}</option>;
                                                        })}
                                                    </select>
                                                    <label className={s_Select.label} htmlFor={`ps-conditions-${index}-value`}>Оберіть дію</label>
                                                </div>
                                                {formH.errors?.conditions?.[index]?.operator && <p className="form-input-hint">
                                                    {formH.errors?.conditions[index]?.operator?.message}
                                                </p>}
                                            </div>
                                        </Fragment>
                                    )
                                }
                                return null;
                            })}

                        </div>

                        {/* conditions[].value */}
                        <div style={{ width: "40%" }}>
                            <input
                                name={`conditions[${index}].attribute_type`}
                                value={typeWatch}
                                type="hidden"
                                ref={formH.register}
                            />
                            {(!['multiselect', 'complex']?.includes(typeWatch)) && (typeWatch !== '') && (
                                <div>
                                    <InputWrapper
                                        label="Введіть значення"
                                        id={`ps-conditions-value-text-${index}`}
                                        errorComponent={formH.errors?.conditions?.[index]?.value &&
                                        <p className="form-input-hint">{formH.errors?.conditions[index]?.value?.message}</p>}
                                    >
                                    <input
                                        className={cn(s_Input.input, {
                                            'is-error': formH.errors?.conditions?.[index]?.value,
                                        })}
                                        id={`ps-conditions-value-text-${index}`}
                                        type="text"
                                        placeholder="Введіть значення"
                                        maxLength={INPUT_MAX_LENGTH}
                                        value={conditions[index]?.value}
                                        onChange={e => handleOnChange(e, index, 'value', setConditions)}
                                    />
                                    </InputWrapper>
                                    <input name={`conditions[${index}].value`} type="hidden" value={conditions[index]?.value} ref={formH.register({required: VALIDATION.select.required})} />
                                </div>
                            )}

                            {data && typeWatch === 'complex' && (
                                <div className={s_Select.select__inner} style={{ margin: 0 }}>
                                    <div className={s_Select.select__wrapper}>
                                        <select
                                            name={`conditions[${index}].value`}
                                            className={cn(s_Select.select, {
                                                [s_Select.select_error]: formH?.errors?.conditions?.[index]?.value
                                            })}
                                            id={`ps-conditions-value-${index}`}
                                            ref={formH.register({
                                                required:  VALIDATION.select.required
                                            })}
                                            value={item?.value}
                                            onChange={e => handleOnChange(e, index, 'value', setConditions)}
                                        >
                                            {!!data?.selections?.length && data?.selections?.map((sel, n) => {
                                                return <option value={sel.id} key={`selection-item-${n}`}>{sel.title}</option>;
                                            })}
                                        </select>
                                        <label className={s_Select.label} htmlFor={`ps-conditions-value-${index}`}>Вибірки</label>
                                    </div>
                                </div>
                            )}
                            {data && typeWatch === 'multiselect' && (
                                <div>
                                    {!!categoryData?.length && (
                                        <TreeCustom
                                            categoryArray={categoryData}
                                            selectValue={conditions[index]?.value ? conditions[index]?.value : ''}
                                            setArrayTrigger={(checked) => {
                                                setConditions((prev) => {
                                                    const temp = [...prev];
                                                    temp[index].value = checked;
                                                    return temp;
                                                })
                                            }}
                                            formH={formH}
                                            name={`conditions[${index}].value`}
                                            id={`conditions-${index}-value`}
                                        />
                                    )}
                                </div>
                            )}
                        </div>

                        <div>
                            <button
                                type="button"
                                className={s.btn_danger}
                                onClick={() => removeHandler(item, setConditions)}
                            >
                                <img src={icon_delete} alt="icon delete" />
                            </button>
                        </div>
                    </div>
                )
            })}

            <div style={{ marginTop: '10px' }}>
                <Button purple onClick={handleAddCondition}>Добавити</Button>
            </div>
        </div>
    );
};

export default Conditions;