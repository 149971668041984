import React, { useEffect, useState } from "react";
import s_Modal from "./modal.module.scss";
import s_Table from "../orderTable.module.scss";
import icon_update from "../../../../assets/icons/icon_update.svg";
import icon_lock from "../../../../assets/icons/icon_lock.svg";
import icon_unlock from "../../../../assets/icons/icon_unlock.svg";
import downloadIcon from "../../../../assets/icons/icon_download.svg";
import Button from "../../../../components/ui/Button";
import { createOrderReceipt, getOrderReceipt, orderReceiptStatus } from "../../../../lib/api/order";
import { closeShift, getShiftStatus, openShift } from "../../../../lib/api/shift";
import getTZtoDate from "../../../../lib/getTZtoDate";
import { toast } from "react-toastify";
import cn from "classnames";
import { refreshToken } from "../../../../lib/crud/refreshToken";
import errorMessage from "../../../../lib/errorMessage";

const ModalFiscalReceipt = ({ active, setActive, title, id }) => {
    const [receiptData, setReceiptData] = useState([]);
    const [disabledAll, setDisabledAll] = useState(true);

    const updateReceipt = async (showMessage = false) => {
        await getOrderReceipt(id).then((res) => {
            setReceiptData(res.data.logs);
            if(showMessage) {
                toast('Информация обновлена!', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }).catch(async (err) => {
            if(err?.response?.status === 401) {
                await refreshToken(async () => {
                    await getOrderReceipt(id).then((res) => {
                        setReceiptData(res.data.logs);
                        if(showMessage) {
                            toast('Информация обновлена!', {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    })
                })
            } else {
                await errorMessage(err, '')
            }
        });
    };

    const createReceipt = async () => {
        await createOrderReceipt(id).then((res) => {
            if(res.data.message || res.data.error) {
                toast.error(res.data.message || res.data.error, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast('Чек создан!', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }).catch(async (err) => {
            if(err?.response?.status === 401) {
                await refreshToken(async () => {
                    await createOrderReceipt(id).then((res) => {
                        if(res.data.message || res.data.error) {
                            toast.error(res.data.message || res.data.error, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        } else {
                            toast('Чек создан!', {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    })
                })
            } else {
                await errorMessage(err, '')
            }
        });
        await updateReceipt();
    };

    const handleClickDisabled = () => setDisabledAll(!disabledAll);

    useEffect(() => {
        getOrderReceipt(id).then((res) => {
            setReceiptData(res.data.logs);
        }).catch(async (err) => {
            if(err?.response?.status === 401) {
                await refreshToken(async () => {
                    await getOrderReceipt(id).then((res) => {
                        setReceiptData(res.data.logs);
                    })
                })
            } else {
                await errorMessage(err, '')
            }
        });
    }, []);

    return (
        <div
            className={active ? `${s_Modal.modal} ${s_Modal.active}` : s_Modal.modal}
            onClick={() => setActive(false)}
        >
            <div
                className={s_Modal.modal__content}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={s_Modal.modal__header}>
                    <div>
                        <div className={s_Modal.modal__title}>{title}</div>
                        <div className={s_Modal.modal__update} onClick={updateReceipt}>
                            <img src={icon_update} alt="Icon update" />
                            Обновить
                        </div>
                    </div>
                    <div className={cn(s_Modal.modal__unlock, {
                        [s_Modal.modal__lock]: disabledAll,
                    })} onClick={handleClickDisabled}>
                        {disabledAll ? (
                            <>
                                <img src={icon_lock} alt="Icon lock" />
                                Разблокировать
                            </>
                        ) : (
                            <>
                                <img src={icon_unlock} alt="Icon lock" />
                                Заблокировать
                            </>
                        )}
                    </div>
                </div>
                <div className={s_Modal.modal__box}>
                    <div className={s_Table.table__inner}>
                        <div className={s_Table.table__wrapper}>
                            {!!receiptData.length ? (
                                <table className={s_Table.table}>
                                    <thead className={s_Table.thead}>
                                    <tr>
                                        <th>
                                            <div>Дата</div>
                                        </th>
                                        <th>
                                            <div>Ответ</div>
                                        </th>
                                        <th>
                                            <div>Тип</div>
                                        </th>
                                        <th>
                                            <div>Чек</div>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody className={s_Table.tbody}>
                                    {!!receiptData.length && receiptData.map((receipt, indexReceipt) => (
                                        <tr key={`order-modal-fiscal-receipt-item-${receipt?.id || indexReceipt}`}>
                                            <td>
                                                <div>{getTZtoDate(receipt.created_at, true)}</div>
                                            </td>
                                            <td>
                                                <div>{receipt.response}</div>
                                            </td>
                                            <td>
                                                <div>{receipt.type}</div>
                                            </td>
                                            <td>
                                                <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                                                    {receipt?.receipt_id && (
                                                        <div style={{ cursor: 'pointer' }} onClick={async () => {
                                                            await orderReceiptStatus(id, receipt.receipt_id).then((res) => {
                                                                if(res.data.message || res.data.error) {
                                                                    toast.error(res.data.message || res.data.error, {
                                                                        position: "top-center",
                                                                        autoClose: 5000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: true,
                                                                        progress: undefined,
                                                                    });
                                                                } else {
                                                                    toast('Чек возврата создан!', {
                                                                        position: "top-center",
                                                                        autoClose: 5000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: true,
                                                                        progress: undefined,
                                                                    });
                                                                }
                                                            }).catch(async (err) => {
                                                                if(err?.response?.status === 401) {
                                                                    await refreshToken(async () => {
                                                                        await orderReceiptStatus(id, receipt.receipt_id).then((res) => {
                                                                            if(res.data.message || res.data.error) {
                                                                                toast.error(res.data.message || res.data.error, {
                                                                                    position: "top-center",
                                                                                    autoClose: 5000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: true,
                                                                                    progress: undefined,
                                                                                });
                                                                            } else {
                                                                                toast('Чек возврата создан!', {
                                                                                    position: "top-center",
                                                                                    autoClose: 5000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: true,
                                                                                    progress: undefined,
                                                                                });
                                                                            }
                                                                        })
                                                                    })
                                                                } else {
                                                                    await errorMessage(err, '')
                                                                }
                                                            });
                                                            await updateReceipt();
                                                        }}>
                                                            <img src={icon_update} alt="Icon update" />
                                                        </div>
                                                    )}
                                                    {receipt.link && (
                                                        <div>
                                                            <a href={receipt.link} target="_blank">
                                                                <img src={downloadIcon} alt="Icon download" />
                                                            </a>
                                                        </div>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            ) : (
                                <div>Нет данных</div>
                            )}

                        </div>
                    </div>
                </div>
                <div className={s_Modal.modal__footer}>
                    <div className={s_Modal.modal__group} style={{justifyContent: 'space-between'}}>
                        <div className={s_Modal.modal__buttons}>
                            <div>
                                <Button purple disabled={disabledAll} onClick={async () => {
                                    await getShiftStatus().then((res) => {
                                        toast(res.data.message || res.data.lasterr, {
                                            position: "top-center",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                        });
                                    }).catch(async (err) => {
                                        if(err?.response?.status === 401) {
                                            await refreshToken(async () => {
                                                await getShiftStatus().then((res) => {
                                                    toast(res.data.message || res.data.lasterr, {
                                                        position: "top-center",
                                                        autoClose: 5000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        progress: undefined,
                                                    });
                                                })
                                            })
                                        } else {
                                            await errorMessage(err, '')
                                        }
                                    });
                                }}>Статус смены</Button>
                            </div>
                            <div>
                                <Button purple disabled={disabledAll} onClick={async () => {
                                    await closeShift().then((res) => {
                                        toast(res.data.message || res.data.lasterr, {
                                            position: "top-center",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                        });
                                    }).catch(async (err) => {
                                        if(err?.response?.status === 401) {
                                            await refreshToken(async () => {
                                                await closeShift().then((res) => {
                                                    toast(res.data.message || res.data.lasterr, {
                                                        position: "top-center",
                                                        autoClose: 5000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        progress: undefined,
                                                    });
                                                })
                                            })
                                        } else {
                                            await errorMessage(err, '')
                                        }
                                    });
                                }}>Закрыть смену</Button>
                            </div>
                            <div>
                                <Button purple disabled={disabledAll} onClick={async () => {
                                    await openShift().then((res) => {
                                        toast(res.data.message || res.data.lasterr, {
                                            position: "top-center",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                        });
                                    }).catch(async (err) => {
                                        if(err?.response?.status === 401) {
                                            await refreshToken(async () => {
                                                await openShift().then((res) => {
                                                    toast(res.data.message || res.data.lasterr, {
                                                        position: "top-center",
                                                        autoClose: 5000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        progress: undefined,
                                                    });
                                                })
                                            })
                                        } else {
                                            await errorMessage(err, '')
                                        }
                                    });
                                }}>Открыть смену</Button>
                            </div>
                            <div>
                                <Button purple onClick={createReceipt} disabled={disabledAll}>Создать</Button>
                            </div>
                        </div>

                        <div>
                            <Button border onClick={() => setActive(false)}>Закрыть</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalFiscalReceipt;