import { parseAll } from '../../lib/api/_parseApi';
import {
    editCurtRule,
    getCurtRulesActionTypes,
    getCurtRulesConditions,
    getCurtRulesDiscountPks
} from "../../lib/api/cart_rules";
import { getSpecOffersALl } from "../../lib/api/specOffers";
import { getCatTree } from "../../lib/api/categories";
import { getSelections } from "../../lib/api/selections";

export const getData = () => Promise.allSettled([
    getCurtRulesDiscountPks(), getSpecOffersALl(), getCurtRulesConditions(), getCurtRulesActionTypes(), getCatTree(),
    getSelections({ per_page: 10000 })
])
    .then(async (res) => {

        const parseActionTypes = Object?.entries(res[3]?.value?.data)?.map(item => item[1]);

        const parseCategoriesNodes = (nodes) => {

            if (!!nodes.children?.length) {
                return {
                    value: nodes.id,
                    label: nodes.title,
                    children: nodes.children.map((node) => parseCategoriesNodes(node))
                }
            }

            return {
                value: nodes.id,
                label: nodes.title,
                children: null
            }
        }

        const parseCategories = res[4]?.value?.data?.[0]?.children?.map(item => {
            return parseCategoriesNodes(item);
        });

        return ({
            fields: res[0]?.value?.data,
            special_offers: res[1]?.value?.data,
            conditions: res[2]?.value?.data,
            action_types: parseActionTypes,
            categories: parseCategories,
            selections: res[5]?.value?.data?.data
        })
    });

export const edit = editCurtRule;

export const parseDataSend = (data, soId) => {
    let d = { ...data };
    const isUpdate = typeof soId !== 'undefined';

    if (!!d?.special_offer_excepted_ids?.length) {
        d.special_offer_excepted_ids = d.special_offer_excepted_ids.map(item => +item);
    } else {
        d.special_offer_excepted_ids = [];
    }

    d = parseAll(d, !isUpdate, ['is_active']);

    d.name = 'Налаштування знижок за ПКС';

    d.domains = [1];
    d.coupon_type = 4;

    if (!!d?.conditions?.length) {
        d.conditions = d.conditions?.map(item => {

            if (item?.attribute === 'product|category_ids') {
                item.value = [item?.value];
            }

            return item;
        });
    } else {
        d.conditions = [];
    }

    if (!!d?.discount_conditions?.length) {
        d.discount_conditions = d?.discount_conditions?.map((item, index) => {
            if (d?.conditions?.[index]?.value) {
                item.value = d?.conditions?.[index]?.value;
            }

            if (d?.conditions?.[index]?.attribute) {
                if (d?.conditions?.[index]?.attribute === 'product|category_ids') {
                    item.attribute = 'product|category_id';
                    if(Array.isArray(item?.value)) {
                        item.value = item?.value?.[0] || '';
                    }
                } else {
                    item.attribute = d?.conditions?.[index]?.attribute;
                }
            }

            return item;
        })
    } else {
        d.discount_conditions = [];
    }

    return d;
};

export const parseDataGet = (data) => {
    const d = { ...data };

    const parseConditions = [];

    if (!!d?.conditions?.length) {
        d.conditions = d.conditions?.map(item => {

            if (item?.attribute === 'product|category_ids') {
                if(Array.isArray(item?.value)) {
                    item.value = item?.value?.[0] || '';
                }
            }

            return item;
        });
    }

    if (!!d?.discount_conditions?.length) {
        d.discount_conditions = d?.discount_conditions?.map(item => {
            if (item?.attribute === 'product|category_id') {
                item.attribute = 'product|category_ids';
            }

            return item;
        })
    }

    if (!!d?.conditions?.length && !!d?.discount_conditions?.length) {

        d.conditions.forEach((item, index) => {
            parseConditions.push({
                ...item, ...d.discount_conditions[index]
            })
        })
    } else if (!!d?.discount_conditions?.length) {
        d.discount_conditions.forEach((item, index) => {
            parseConditions.push({
                ...item
            })
        })
    } else if (!!d?.conditions?.length) {
        d.conditions.forEach((item, index) => {
            parseConditions.push({
                ...item
            })
        })
    }

    d.conditions_merge = parseConditions;

    return d;
};

